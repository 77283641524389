import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../css/Ticket.css';

export const Ticket = () => {
    const navigate = useNavigate();
    const [currentUserEmail, setCurrentUserEmail] = useState(null); 
    const auth = getAuth();

    useEffect(() => {
        // 사용자의 로그인 상태 감지
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log('Logged in user email:', user.email); 
                setCurrentUserEmail(user.email);
            } else {
                console.log('No user logged in');
                setCurrentUserEmail(null);
            }
        });

        // Cleanup 함수
        return () => unsubscribe();
    }, [auth]);

    const handleTypeClick = (type) => {
        navigate(`/ticket/booking/${type}`);
    }

    // 이메일의 로컬 부분만 추출하는 함수
    const getLocalPartOfEmail = (email) => {
        return email.split('@')[0];
    }

    return (
        <>
           <div className='noScroll'>
               
                <div className='TicketWrap'>
                    <h1 className="Title">티켓 예매</h1>
                    <div className='TypesWrap'>
                        <div className='Types Item' onClick={() => handleTypeClick('musical')}>뮤지컬</div>
                        <div className='Types Item' onClick={() => handleTypeClick('concert')}>콘서트</div>
                        <div className='Types Item' onClick={() => handleTypeClick('play')}>연극</div>
                        <div className='Types Item' onClick={() => handleTypeClick('classic')}>클래식/무용</div>
                        <div className='Types Item' onClick={() => handleTypeClick('sports')}>스포츠</div>
                        <div className='Types Item' onClick={() => handleTypeClick('exhibition')}>전시</div>
                        {/* 수정용페이지 (ID: test001)만 표시 */}
                        {currentUserEmail && getLocalPartOfEmail(currentUserEmail) === 'test001' && (
                            <div className='Types Item' onClick={() => handleTypeClick('master')}>
                                수정용페이지
                            </div>
                        )}
                    </div>
                </div>
           </div>
        </>
    );
}