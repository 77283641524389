import { HeaderMenuERP } from "../../components/HeaderMenu_ERP";
import CalendarComponent from "./ERPCalendar";
import { useState } from "react";
import EventModal from "./EventAdd";

export const Schedule = () => {

  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태


  const toggleModal = () => setIsModalOpen(!isModalOpen);

    return (
      <div className="ERP-Wrap">
      <HeaderMenuERP/>
      <CalendarComponent />
      <div className='Schedule'>
              <button className='Schedule_Add' onClick={toggleModal}>📅 오늘의 일정을 등록해보세요.</button>
              {isModalOpen && <EventModal onClose={toggleModal} />}
            </div>
      </div>
    );
  };
  