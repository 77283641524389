import { useState, useEffect } from 'react';
import '../css/DashBoard.css';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    Cell, PieChart, Pie,
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane, faHeart, faComment } from '@fortawesome/free-regular-svg-icons';
import { generateFakeActivityFeedsData, generateFakeMentionsData } from '../data/Datas';
import { generateFakeLatestPostData, generateFakeSchedulePostData } from '../data/Datas';
const today = new Date();
const lastTenDays = [];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF5733'];
for (let i = 9; i >= 0; i--) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);

    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();

    lastTenDays.push({ name: `${month} ${day}일`, reach: Math.floor(Math.random() * 10000) });
}

const reachData = lastTenDays.map(({ name }) => ({
    name,
    reach: Math.floor(Math.random() * 10000),
}));

const shareData = lastTenDays.map(({ name }) => ({
    name,
    share: Math.floor(Math.random() * 5000),
}));

const newFollowersData = lastTenDays.map(({ name }) => ({
    name,
    newFollowers: Math.floor(Math.random() * 100),
}));

const LikesData = lastTenDays.map(({ name }) => ({
    name,
    Likes: Math.floor(Math.random() * 1500),
}));

const profileVisitsData = lastTenDays.map(({ name }) => ({
    name,
    profileVisits: Math.floor(Math.random() * 100),
}));
const linkClicksData = lastTenDays.map(({ name }) => ({
    name,
    linkClicks: Math.floor(Math.random() * 100),
}));
const EmailClicksData = lastTenDays.map(({ name }) => ({
    name,
    EmailClicks: Math.floor(Math.random() * 100),
}));



function RandomNum({ msg }) {
    const [TodayRandomNum, setTodayRandomNum] = useState(null);

    useEffect(() => {
        const randomLikes = Math.floor(Math.random() * 700);
        setTodayRandomNum(randomLikes);
    }, []);

    return (
        <>
            {TodayRandomNum !== null &&
                <div className='infos'>
                    <p>{msg}</p>
                    <p>{TodayRandomNum}</p>
                </div>}
        </>

    );
}

export default RandomNum;


const LikesToday = LikesData[LikesData.length - 1].Likes; // 오늘의 Likes 수


const AgeData = [
    { name: '18-24', percentage: .25 },
    { name: '25-34', percentage: .35 },
    { name: '35-44', percentage: .23 },
    { name: '45+', percentage: .17 },
];

const GenderData = [
    { name: '♂', percentage: .35 },
    { name: '♀', percentage: .65 },
];


export const Dashboard = () => {
    const [active1Tab, setActive1Tab] = useState('reach'); // 탭변경용
    const [active2Tab, setActive2Tab] = useState('activityfeed'); // 2 번째 탭 추가
    const [active3Tab, setActive3Tab] = useState('latestpost'); // 세 번째 탭 추가

    const handle1TabClick = (tab) => {
        setActive1Tab(tab);
    };

    const handle2TabClick = (tab) => {
        setActive2Tab(tab);
    };
    const handle3TabClick = (tab) => {
        setActive3Tab(tab);
    };


    const PercentageChange = () => {
        const lastData = shareData[shareData.length - 1].share;
        const secondLastData = shareData[shareData.length - 2].share;

        const change = lastData - secondLastData;
        const percentageChange = (change / secondLastData) * 100;

        return percentageChange.toFixed(1);  // 소수점 1자리까지 반환합니다.
    }

    const percentageChange = PercentageChange(); // 백분율 출력용.
    const lastShareValue = shareData[shareData.length - 1].share; // 마지막 share 값



    const PercentageChange2 = (data, property) => {
        if (!Array.isArray(data) || data.length < 2) {
            return "백분율 변화를 계산할 데이터가 충분하지 않습니다";
        }

        const lastData = data[data.length - 1];
        const secondLastData = data[data.length - 2];

        if (!lastData.hasOwnProperty(property) || !secondLastData.hasOwnProperty(property)) {
            return "유효하지 않은 속성 이름입니다";
        }

        const lastPropertyValue = lastData[property];
        const secondLastPropertyValue = secondLastData[property];

        if (secondLastPropertyValue === 0) {
            secondLastPropertyValue++;
            return "두 번째 마지막 데이터가 0일 때 백분율 변화를 계산할 수 없습니다";
        }

        const change = lastPropertyValue - secondLastPropertyValue;
        const percentageChange = (change / secondLastPropertyValue) * 100;

        return percentageChange.toFixed(1);
    }


    const percentageChange2 = PercentageChange2(); // 백분율 출력용.
    // const lastShareValue2 = newFollowersData[newFollowersData.length - 1].newFollowers; // 마지막 share 값




    const percentageChange2Reach = PercentageChange2(reachData, "reach");
    const percentageChange2Share = PercentageChange2(shareData, "share");
    const percentageChange2Likes = PercentageChange2(LikesData, "Likes");
    const percentageChange2Followers = PercentageChange2(newFollowersData, "newFollowers");
    const percentageChange2ProfileVisits = PercentageChange2(profileVisitsData, "profileVisits");
    const percentageChange2LinkClicks = PercentageChange2(linkClicksData, "linkClicks");
    const percentageChange2EmailClicks = PercentageChange2(EmailClicksData, "EmailClicks");


    const lastShareValue2 = newFollowersData[newFollowersData.length - 1].newFollowers; // 마지막 share 값
    const lastShareValueReach = reachData[reachData.length - 1].reach;
    const lastShareValueShare = shareData[shareData.length - 1].share;
    const lastShareValueLikes = LikesData[LikesData.length - 1].Likes;
    const lastShareValueFollowers = newFollowersData[newFollowersData.length - 1].newFollowers;
    const lastShareValueProfileVisits = profileVisitsData[profileVisitsData.length - 1].profileVisits;
    const lastShareValueLinkClicks = linkClicksData[linkClicksData.length - 1].linkClicks;
    const lastShareValueEmailClicks = EmailClicksData[EmailClicksData.length - 1].EmailClicks;


    // 최근 포스트,스케줄 부분
    const [data, setData] = useState({ latestposts: [] });

    useEffect(() => {
        const latestPostsData = generateFakeLatestPostData();
        const sortedPosts = latestPostsData.sort((a, b) => new Date(b.date) - new Date(a.date));

        setData({
            latestposts: sortedPosts,
            scheduleposts: generateFakeSchedulePostData(),
        });
    }, []);

    // 모달 열기용
    const [isSideMenuOpen, setSideMenuOpen] = useState(false);

    // 외부 클릭 감지 함수
    useEffect(() => {
        function handleClickOutside(event) {
            // 모달 외부 클릭 감지
            if (isSideMenuOpen && !event.target.closest('.modal-sidebar')) {
                setSideMenuOpen(false);
            }
        }

        // 외부 클릭 이벤트 리스너 추가
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // 정리
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isSideMenuOpen]);

    return (
        <>
            <div className="container">
                <h1 className='Title'>Instagram 대시보드</h1>
                <button className='sidebarmenu' onClick={() => setSideMenuOpen(true)}>
                    <FontAwesomeIcon icon={faPaperPlane} style={{ fontSize: '16px', color: '#9698A6' }} />
                </button>
                <div className="content">
                    <div className="summary">
                        <RandomNum msg={"Followers"} />
                        <RandomNum msg={"Following"} />
                        <RandomNum msg={"Comments"} />
                        <div className='infos'><p>Likes</p><p>{LikesToday}</p></div>
                    </div>
                    <div className="chart">
                        <div className="tabs">
                            <button
                                className={`tab-button ${active1Tab === 'reach' ? 'active' : ''}`}
                                onClick={() => handle1TabClick('reach')}
                            >
                                Reach
                            </button>
                            <button
                                className={`tab-button ${active1Tab === 'share' ? 'active' : ''}`}
                                onClick={() => handle1TabClick('share')}
                            >
                                Post Share
                            </button>
                            <button
                                className={`tab-button ${active1Tab === 'newFollowers' ? 'active' : ''}`}
                                onClick={() => handle1TabClick('newFollowers')}
                            >
                                New Follower
                            </button>
                            <button
                                className={`tab-button ${active1Tab === 'likes' ? 'active' : ''}`}
                                onClick={() => handle1TabClick('likes')}
                            >
                                Likes
                            </button>
                        </div>
                        <div className="chart">
                            {active1Tab === 'reach' && (
                                <div>
                                    <h2>Reach</h2>
                                    <ResponsiveContainer width="100%" aspect={6 / 5}>
                                        <LineChart data={reachData}>
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="reach" stroke={COLORS[0]} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            )}
                            {active1Tab === 'share' && (
                                <div>
                                    <h2>Post Share</h2>
                                    <ResponsiveContainer width="100%" aspect={6 / 5}>
                                        <LineChart data={shareData}>
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="share" stroke={COLORS[1]} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            )}
                            {active1Tab === 'newFollowers' && (
                                <div>
                                    <h2>New Follower</h2>
                                    <ResponsiveContainer width="100%" aspect={6 / 5}>
                                        <LineChart data={newFollowersData}>
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="newFollowers" stroke={COLORS[2]} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            )}
                            {active1Tab === 'likes' && (
                                <div>
                                    <h2>Likes</h2>
                                    <ResponsiveContainer width="100%" aspect={6 / 5}>
                                        <LineChart data={LikesData}>
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="Likes" stroke={COLORS[3]} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="chart">
                        <div className='GridBox3'>

                            <div className='GridBox3-Item'>
                                <div>
                                    <h2>Post Share</h2>
                                    <p>{lastShareValue}</p>
                                    <p className={percentageChange2Share < 0 ? 'red' : 'green'}>
                                        {percentageChange2Share}%
                                    </p>
                                    <ResponsiveContainer width="100%" aspect={6 / 5}>
                                        <LineChart data={shareData}>
                                            <Line
                                                type="monotone"
                                                dataKey="share"
                                                stroke={percentageChange2Share < 0 ? 'red' : 'rgb(0, 196, 159)'}
                                                dot={false}
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <div className='GridBox3-Item'>
                                <div>
                                    <h2>New Followers</h2>
                                    <p>{lastShareValueFollowers}</p>
                                    <p className={percentageChange2Followers < 0 ? 'red' : 'green'}>
                                        {percentageChange2Followers}%
                                    </p>
                                    <ResponsiveContainer width="100%" aspect={6 / 5}>
                                        <LineChart data={newFollowersData}>
                                            <Line
                                                type="monotone"
                                                dataKey="newFollowers"
                                                stroke={percentageChange2Followers < 0 ? 'red' : 'rgb(0, 196, 159)'}
                                                dot={false}
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <div className='GridBox3-Item'>
                                    <div className='chart3s'>
                                        <h2>Profile Visits</h2>
                                        <p className={percentageChange2ProfileVisits < 0 ? 'red' : 'green'}>
                                        </p>
                                        <ResponsiveContainer width="50%" height={50}>
                                            <LineChart data={profileVisitsData}>
                                                <Line
                                                    type="monotone"
                                                    dataKey="profileVisits"
                                                    stroke={percentageChange2ProfileVisits < 0 ? 'red' : 'rgb(0, 196, 159)'}
                                                    dot={false}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                        <p>{lastShareValueProfileVisits}
                                            {percentageChange2ProfileVisits < 0 ? (
                                                <FontAwesomeIcon icon={faArrowDown} className='red' />
                                            ) : (
                                                <FontAwesomeIcon icon={faArrowUp} className='green' />
                                            )}
                                        </p>
                                    </div>
                                    <div className='chart3s'>
                                        <h2>Link Clicks</h2>
                                        <p className={percentageChange2LinkClicks < 0 ? 'red' : 'green'}>
                                        </p>
                                        <ResponsiveContainer width="50%" height={50}>
                                            <LineChart data={linkClicksData}>
                                                <Line
                                                    type="monotone"
                                                    dataKey="linkClicks"
                                                    stroke={percentageChange2LinkClicks < 0 ? 'red' : 'rgb(0, 196, 159)'}
                                                    dot={false}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                        <p>{lastShareValueLinkClicks}
                                            {percentageChange2LinkClicks < 0 ? (
                                                <FontAwesomeIcon icon={faArrowDown} className='red' />
                                            ) : (
                                                <FontAwesomeIcon icon={faArrowUp} className='green' />
                                            )}
                                        </p>
                                    </div>
                                    <div className='chart3s'>
                                        <h2>Email Clicks</h2>
                                        <p className={percentageChange2EmailClicks < 0 ? 'red' : 'green'}>
                                        </p>
                                        <ResponsiveContainer width="50%" height={50}>
                                            <LineChart data={EmailClicksData}>
                                                <Line
                                                    type="monotone"
                                                    dataKey="EmailClicks"
                                                    stroke={percentageChange2EmailClicks < 0 ? 'red' : 'rgb(0, 196, 159)'}
                                                    dot={false}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                        <p>{lastShareValueEmailClicks}
                                            {percentageChange2EmailClicks < 0 ? (
                                                <FontAwesomeIcon icon={faArrowDown} className='red' />
                                            ) : (
                                                <FontAwesomeIcon icon={faArrowUp} className='green' />
                                            )}
                                        </p>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="chart">
                        <div className="tabs">
                            <button
                                className={`tab-button ${active3Tab === 'latestpost' ? 'active' : ''}`}
                                onClick={() => handle3TabClick('latestpost')}
                            >
                                Latest Posts
                            </button>
                            <button
                                className={`tab-button ${active3Tab === 'schedule' ? 'active' : ''}`}
                                onClick={() => handle3TabClick('schedule')}
                            >
                                Schedule Posts
                            </button>
                        </div>
                        <div className='userwrap'>
                            {active3Tab === 'latestpost' && (
                                <>
                                    <ul className='mentions'>
                                        {data.latestposts.map((latestpost) => (
                                            <li className='postwrap' key={latestpost.id}>
                                                <div className="vertical-text">{latestpost.date}</div>
                                                <div>
                                                    {/* Picsum 랜덤 이미지 불러오기 */}
                                                    <img
                                                        src={`https://picsum.photos/100/100?random=${latestpost.id}`}
                                                        alt="User Profile"
                                                    />
                                                </div>
                                                <div className='contents'>
                                                    <div>{"@" + latestpost.id}</div>
                                                    <div>{latestpost.mention}</div>
                                                    {/* <div>{"좋,댓,공유"}</div> */}
                                                    <div className='flx_spbt'>
                                                        <div>
                                                            <FontAwesomeIcon className='icon' icon={faHeart} />
                                                            <span>1</span>
                                                        </div>
                                                        <div>
                                                            <FontAwesomeIcon className='icon' icon={faComment} />
                                                            <span>2</span>
                                                        </div>
                                                        <div>
                                                            <FontAwesomeIcon className='icon' icon={faPaperPlane} />
                                                            <span>3</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {active3Tab === 'schedule' && (
                                <>
                                    <ul className='mentions'>
                                        {data.scheduleposts && data.scheduleposts.map((schedule) => (
                                            <li className='postwrap2' key={schedule.id}>
                                                <div>{schedule.mention}</div>
                                                <div>{"예약일:"}{schedule.scheduledDate}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </div>

                    </div>
                    <div className="chart">

                        <div className='chart2s'>

                            <div className="chart2s_item">
                                <h2>Follower's Age</h2>
                                <ResponsiveContainer width="100%" height={150}>
                                    <PieChart>
                                        <Pie
                                            dataKey="percentage"
                                            data={AgeData}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={50}
                                            outerRadius={70}
                                            fill="#8884d8"
                                            labelLine={false}
                                        // label={({ percent, name }) => `${name} ${Math.round(percent * 100)}%`}
                                        >
                                            {AgeData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index]} />
                                            ))}
                                        </Pie>
                                        <Tooltip formatter={(value) => `${Math.round(value * 100)}%`} />
                                    </PieChart>
                                </ResponsiveContainer>
                                <div className='index' style={{ textAlign: 'center', marginTop: 10 }}>
                                    {AgeData.map((entry, index) => (
                                        <div key={`label-${index}`}>
                                            <span style={{ color: COLORS[index] }}>■</span>
                                            {`${entry.name}: ${Math.round(entry.percentage * 100)}%`}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="chart2s_item">
                                <h2>Follower's Gender</h2>
                                <ResponsiveContainer width="100%" height={150}>
                                    <PieChart>
                                        <Pie
                                            dataKey="percentage"
                                            data={GenderData}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={50}
                                            outerRadius={70}
                                            fill="#8884d8"
                                            labelLine={false}
                                        // label={({ percent, name }) => `${name} ${Math.round(percent * 100)}%`}
                                        >
                                            {GenderData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index]} />
                                            ))}
                                        </Pie>
                                        <Tooltip formatter={(value) => `${Math.round(value * 100)}%`} />
                                    </PieChart>
                                </ResponsiveContainer>
                                <div className='index' style={{ textAlign: 'center', marginTop: 10 }}>
                                    {GenderData.map((entry, index) => (
                                        <div key={`label-${index}`}>
                                            <span style={{ color: COLORS[index] }}>■</span>
                                            {`${entry.name}: ${Math.round(entry.percentage * 100)}%`}
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div>
                    {/* 모달 창 */}
                    {isSideMenuOpen && (
                        <div className='modal'>
                            <div className='modal-sidebar'>
                                <div className='userwrap'>

                                    <div className="tabs">
                                        <button
                                            className={`tab-button ${active2Tab === 'activityfeed' ? 'active' : ''}`}
                                            onClick={() => handle2TabClick('activityfeed')}
                                        >
                                            Activity Feeds
                                        </button>
                                        <button
                                            className={`tab-button ${active2Tab === 'mentions' ? 'active' : ''}`}
                                            onClick={() => handle2TabClick('mentions')}
                                        >
                                            Mentions
                                        </button>
                                    </div>

                                    {active2Tab === 'activityfeed' && (
                                        <>
                                            <ul className='mentions'>
                                                {generateFakeActivityFeedsData().map((feed) => (
                                                    <li key={feed.id}>{"@" + feed.id}{feed.content}</li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                    {active2Tab === 'mentions' && (
                                        <>
                                            <ul className='mentions'>
                                                {generateFakeMentionsData().map((mention) => (
                                                    <li key={mention.id}>{"@" + mention.id}{mention.content}</li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};