import { useState, useEffect } from 'react';
import axios from 'axios';

const useHolidayData = () => {
    const [holiDays, setHoliDays] = useState(['']);

    useEffect(() => {
        const fetchHolidays = async () => {
            try {
                const response = await axios.get('http://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getRestDeInfo', {
                    params: {
                        serviceKey: 'Ur8v4YHYu8+78+hh85xlRcr/MGzI6OzJ875TznrewD/VweFWu+7x3dpSmXqtcFeZgEmydYV0a1XSfuT5ocfeZQ==',
                        solYear: new Date().getFullYear(), // 현재 연도
                        _type: 'json', // JSON 형태로 응답 받음
                    },
                });

                // API 응답에서 공휴일 데이터를 추출하고 형식을 맞추기
                const items = response.data.response.body.items.item || [];
                const holidayEvents = items.map(holiday => ({
                    id: holiday.locdate.toString(), // 문자열로 변환
                    title: holiday.dateName,
                    start: new Date(parseInt(holiday.locdate.toString().substring(0, 4)), parseInt(holiday.locdate.toString().substring(4, 6)) - 1, parseInt(holiday.locdate.toString().substring(6, 8))),
                    end: new Date(parseInt(holiday.locdate.toString().substring(0, 4)), parseInt(holiday.locdate.toString().substring(4, 6)) - 1, parseInt(holiday.locdate.toString().substring(6, 8))),
                    type: 'holiday'
                }));

                setHoliDays(holidayEvents);
            } catch (error) {
                console.error('Error fetching holidays:', error);
            }
        };

        fetchHolidays();
    }, []);

    return holiDays; // 수정된 부분
};

export default useHolidayData;
