import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import ReservationModal from '../components/ReservationModal';
import { LoginModal } from './Login';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../css/TicketBook.css';

export const ExhibitionPage = () => {
  const [exhibitions, setExhibitions] = useState([]);
  const [selectedPerformance, setSelectedPerformance] = useState(null);
  const [user, setUser] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }
  )


  useEffect(() => {
    const fetchClassics = async () => {
      const q = query(collection(db, 'performances'), where('category', '==', 'exhibition'));
      const querySnapshot = await getDocs(q);
      setExhibitions(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchClassics();
  }, []);


  const handlePerformanceClick = (exhibition) => {
    if (!user) {
      setShowLoginModal(true);
    } else {
      setSelectedPerformance(exhibition);
    }
  };


  return (
    <div className="TicketBookWrap">
      <h1 className='Title'>전시회 예매 사이트</h1>
      <div className='ContentsList'>

        <div className="ContentsWrap">
          {exhibitions.map((exhibition) => (
            <div
              key={exhibition.id}
              className="contents"
              onClick={() => handlePerformanceClick(exhibition)}
            >
              <div className='imgwrap'><img className='titleimg' src={exhibition.imageUrl} alt={exhibition.title} /></div>
              <div>
                <h2>{exhibition.title}</h2>
                <p>{exhibition.description}</p>
              </div>
            </div>
          ))}
        </div>
        {selectedPerformance && (
          <ReservationModal
            performance={selectedPerformance}
            user={user}
            onClose={() => setSelectedPerformance(null)}
          />
        )}
        {showLoginModal && (
          <LoginModal
            onLoginSuccess={() => setShowLoginModal(false)}
            onClose={() => setShowLoginModal(false)}
          />
        )}
      </div>
    </div>
  );
};
