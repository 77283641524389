import { signOut } from 'firebase/auth'; 
import { auth } from '../../firebase';
export const Logout = () => {
    // 로그아웃 처리 함수
    const handleLogout = async () => {
        try {
            await signOut(auth);
            alert('로그아웃 되었습니다.');
        } catch (error) {
            console.error('로그아웃 에러:', error);
            alert('로그아웃 중 문제가 발생했습니다.');
        }
    };

    return (
        <button className='LoginBtn' onClick={handleLogout}>로그아웃</button>
    );
};
