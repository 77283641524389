import React, { useState, useEffect } from 'react';
import '../../css/EDMS.css';
import { HeaderMenuERP } from '../../components/HeaderMenu_ERP';
import { ApprovalComplete, ApprovalInProgress, ApprovalRejected, ApprovalRequests, ApprovalWaiting, DraftCreationPage } from '../../components/EdmsPages';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase';

export const EDMS = () => {
  const [activeSidebarItem, setActiveSidebarItem] = useState('draftCreation');
  const [messageCounts, setMessageCounts] = useState({
    draftCreation: 0,
    requestBox: 0,
    approvalWaiting: 0,
    approvalInProgress: 0,
    completedDocuments: 0,
    rejectedDocuments: 0,
    edms: 0,
  });
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Auth 상태 감지 리스너
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/erp/login');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  useEffect(() => {
    if (auth.currentUser) {
      const userDocRef = doc(db, 'users', auth.currentUser.email);
      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          const notifications = doc.data().notifications || {};
          setMessageCounts({
            draftCreation: notifications.draftCreation || 0,
            requestBox: notifications.requestBox || 0,
            approvalWaiting: notifications.approvalWaiting || 0,
            approvalInProgress: notifications.approvalInProgress || 0,
            completedDocuments: notifications.completedDocuments || 0,
            rejectedDocuments: notifications.rejectedDocuments || 0,
            edms: notifications.edms || 0,
          });
        }
      });

      return () => unsubscribe();
    }
  }, [auth.currentUser]);

  const handleSidebarItemClick = (itemName) => {
    setActiveSidebarItem(itemName);
  };

  return (
    <div className="ERP-Wrap">
      <HeaderMenuERP />
      <div className="edms-container">
        <div className="edms-sidebar">
          <h3>기안</h3>
          <div 
            className={`sidebar-item ${activeSidebarItem === 'draftCreation' ? 'active' : ''}`} 
            onClick={() => handleSidebarItemClick('draftCreation')}>
            기안문 작성
            {messageCounts.draftCreation > 0 && <span className="alarm">{messageCounts.draftCreation}</span>}
          </div>
     
          <div 
            className={`sidebar-item ${activeSidebarItem === 'requestBox' ? 'active' : ''}`} 
            onClick={() => handleSidebarItemClick('requestBox')}>
              결재요청함
              {messageCounts.requestBox > 0 && <span className="alarm">{messageCounts.requestBox}</span>}
          </div>
          <h3>결재</h3>

          <div 
            className={`sidebar-item ${activeSidebarItem === 'approvalWaiting' ? 'active' : ''}`} 
            onClick={() => handleSidebarItemClick('approvalWaiting')}>
               결재대기함
               {messageCounts.approvalWaiting > 0 && <span className="alarm">{messageCounts.approvalWaiting}</span>}
          </div>
          <div 
            className={`sidebar-item ${activeSidebarItem === 'approvalInProgress' ? 'active' : ''}`} 
            onClick={() => handleSidebarItemClick('approvalInProgress')}>
             결재진행함
             {messageCounts.approvalInProgress > 0 && <span className="alarm">{messageCounts.approvalInProgress}</span>}
          </div>
          <div 
            className={`sidebar-item ${activeSidebarItem === 'completedDocuments' ? 'active' : ''}`} 
            onClick={() => handleSidebarItemClick('completedDocuments')}>
          완료문서함
          {messageCounts.completedDocuments > 0 && <span className="alarm">{messageCounts.completedDocuments}</span>}
          </div>
          <div 
            className={`sidebar-item ${activeSidebarItem === 'rejectedDocuments' ? 'active' : ''}`} 
            onClick={() => handleSidebarItemClick('rejectedDocuments')}>
                반려문서함
                {messageCounts.rejectedDocuments > 0 && <span className="alarm">{messageCounts.rejectedDocuments}</span>}
          </div>
        </div>
        <div className="edms-content">
          {activeSidebarItem === 'draftCreation' && <DraftCreationPage />}
          {activeSidebarItem === 'requestBox' && <ApprovalRequests />}
          {activeSidebarItem === 'approvalWaiting' && <ApprovalWaiting />}
          {activeSidebarItem === 'approvalInProgress' && <ApprovalInProgress />}
          {activeSidebarItem === 'completedDocuments' && <ApprovalComplete />}
          {activeSidebarItem === 'rejectedDocuments' && <ApprovalRejected />}
        </div>
      </div>
    </div>
  );
};