import React, { useState, useEffect } from 'react';
import '../../css/EDMS.css';
import { HeaderMenuERP } from '../../components/HeaderMenu_ERP';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDocs,collection } from 'firebase/firestore';
import { db } from '../../../firebase';
import '../../css/Am.css'
import { GenerateFinancialReports, ManagePayments, RecordTransactions, VisualizeFinancialData } from '../../components/AMPages';
export const Am = () => {
  const [activeSidebarItem, setActiveSidebarItem] = useState('RecordTransactions');

  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    //Auth 상태 감지 리스너
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/erp/login');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
      const fetchTransactions = async () => {
          const querySnapshot = await getDocs(collection(db, "transactions"));
          const loadedTransactions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setTransactions(loadedTransactions);
      };

      fetchTransactions();
  }, []);

  const renderSelectedContent = () => {
    switch (activeSidebarItem) {
      case 'RecordTransactions':
        return (
          <RecordTransactions/>
        );
        
        case 'VisualizeFinancialData':
          return (
           <VisualizeFinancialData/>
          );
          
      case 'GenerateFinancialReports':
        return (
          <GenerateFinancialReports/>
        );


      default:
        return null;
    }
  };

  return (
    <div className="ERP-Wrap">
      <HeaderMenuERP />
      <h2>회계관리</h2>
      <div className="Am-container">
        <div className="edms-sidebar">
          <div
            className={`sidebar-item ${activeSidebarItem === 'RecordTransactions' ? 'active' : ''}`}
            onClick={() => setActiveSidebarItem('RecordTransactions')}>
            거래 기록 관리
          </div>

          <div
            className={`sidebar-item ${activeSidebarItem === 'VisualizeFinancialData' ? 'active' : ''}`}
            onClick={() => setActiveSidebarItem('VisualizeFinancialData')}>
            재무 데이터 시각화
          </div>
          <div
            className={`sidebar-item ${activeSidebarItem === 'GenerateFinancialReports' ? 'active' : ''}`}
            onClick={() => setActiveSidebarItem('GenerateFinancialReports')}>
            재무 보고서 생성
          </div>

        </div>
        <div className="Am-content">
          {renderSelectedContent()}
        </div>
      </div>
    </div>
  );
};
