import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, deleteDoc, doc, addDoc } from 'firebase/firestore';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../css/ToDoList.css';

export const ToDoList = () => {
  const [todos, setTodos] = useState([]);
  const [newTitle, setNewTitle] = useState('');
  const [newContent, setNewContent] = useState('');
  const [startDate, setStartDate] = useState(getToday());
  const [dueDate, setDueDate] = useState(getToday());
  const [expandedTodoIndex, setExpandedTodoIndex] = useState(null);
  const [isCalendarView, setIsCalendarView] = useState(false); // 달력 뷰 여부
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [sortedTodos, setSortedTodos] = useState([]);

  useEffect(() => {
    const sorted = [...todos].sort((a, b) => new Date(a.end) - new Date(b.end));
    setSortedTodos(sorted);
  }, [todos]);



  useEffect(() => {
    async function fetchData() {
      try {
        const querySnapshot = await getDocs(collection(db, "todolist"));
        const itemsData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setTodos(itemsData);
        console.log("Data from Firestore: ", itemsData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // 필요한 경우 추가적인 작업을 수행할 수 있습니다.
  }, [todos]);

  function getToday() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  const limitedContent = newContent.slice(0, 100);

  const addTodo = async () => {
    if (newTitle.trim() === '' || newContent.trim() === '' || startDate === '' || dueDate === '') {
      alert('제목, 내용, 시작일, 마감일을 모두 입력하세요.');
      return;
    }

    // 시작일과 종료일 비교
    const startDateTime = new Date(startDate);
    const dueDateTime = new Date(dueDate);

    if (startDateTime > dueDateTime) {
      alert('시작일은 종료일보다 이른 날짜여야 합니다.');
      return;
    }

    // Firestore에 할 일 추가
    const docRef = await addDoc(collection(db, "todolist"), {
      title: newTitle,
      content: limitedContent,
      start: startDate,
      end: dueDate,
    });

    setTodos([
      ...todos,
      {
        title: newTitle,
        content: limitedContent,
        start: startDate,
        end: dueDate,
        id: docRef.id,
      },
    ]);

    setNewTitle('');
    setNewContent('');
    setStartDate(getToday());
    setDueDate(getToday());
  };

  const removeTodo = async (id, index, event) => {
    event.stopPropagation(); // 이벤트 버블링 방지

    const confirmDelete = window.confirm('정말 삭제하시겠습니까?');
    if (confirmDelete) {
      // Firestore에서 할 일 삭제
      await deleteDoc(doc(db, "todolist", id));
      console.log("Before removing: ", todos);
      const updatedTodos = todos.filter(todo => todo.id !== id);
      console.log("After removing: ", updatedTodos);
      setTodos(updatedTodos);
    }
    // 상세 정보 표시 중단
    if (expandedTodoIndex === index) {
      setExpandedTodoIndex(null);
    } else if (index < expandedTodoIndex) {
      setExpandedTodoIndex(expandedTodoIndex - 1);
    }

  };
  useEffect(() => {
    console.log("Todos updated: ", todos);
  }, [todos]);

  const deleteEvent = async (id) => {
    const confirmDelete = window.confirm('정말 삭제하시겠습니까?');
    if (confirmDelete) {
      // Firestore에서 삭제
      await deleteDoc(doc(db, "todolist", id));

      // 로컬 상태 업데이트
      setTodos(todos.filter(todo => todo.id !== id));
      setSelectedEvent(null); // 삭제 후 모달 닫기
    }
  };



  const toggleTodoDetails = (index) => {
    if (expandedTodoIndex === index) {
      setExpandedTodoIndex(null);
    } else {
      setExpandedTodoIndex(index);
    }
  };

  const calculateDday = (dueDate) => {
    const today = new Date();
    const dDay = new Date(dueDate);
    const diffInMilliseconds = dDay - today;
    const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

    if (diffInDays < 0) {
      return "마감일 지남";
    }

    return diffInDays;
  };

  const toggleView = () => {
    setIsCalendarView(!isCalendarView);
  };

  useEffect(() => {
    if (selectedEvent) {
      document.body.classList.add('noScroll');
    } else {
      document.body.classList.remove('noScroll');
    }
  }, [selectedEvent]);



  return (
    <div className="TodoList-Wrap">
        <h1 className="Title">To Do List</h1>
      <div className="AddTodo">
        <input
          className="AddTitle"
          type="text"
          placeholder="제목을 입력하세요"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
        />
        <textarea
          rows="4"
          placeholder="내용을 입력하세요."
          value={newContent}
          onChange={(e) => setNewContent(e.target.value)}
        ></textarea>
        <>
          <span className='onlyread'>
            시작일 : {formatDate(startDate)}
          </span>
          <span className='onlyread'>
            종료일 : {formatDate(dueDate)}
          </span>
        </>
        <div className="AddDate">
          시작일 :
          <input
            type="datetime-local" // 시작일에 시간을 포함하는 입력 필드
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />


        </div>
        <div className="AddDate">
          종료일 :
          <input
            type="datetime-local" // 시작일에 시간을 포함하는 입력 필드
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
          />


        </div>
        <button onClick={addTodo}>추가</button>
      </div>

      <ul className="TodoList">
        <div className="ViewToggle">
          <button className='ViewChange' onClick={toggleView}>{isCalendarView ? '리스트 보기' : '달력 보기'}</button>
        </div>
        {isCalendarView ? (
          // 달력 뷰 구현 코드 추가
          <div className="CalendarView">
            <Calendar
              localizer={momentLocalizer(moment)}
              events={sortedTodos.map((todo) => ({
                title: todo.title,
                start: new Date(todo.start),
                end: new Date(todo.end),
                id: todo.id // 여기에서 id 값을 설정합니다.
              }))}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              popup
              components={{
                event: ({ event }) => (
                  <div onClick={() => setSelectedEvent(event)}>
                    {event.title}
                  </div>
                ),
              }}
              views={['month']}
            />
          </div>
        ) : (
          <>
            {sortedTodos.map((todo, index) => (
              <li
                key={todo.id}
                onClick={() => toggleTodoDetails(index)}
                className={
                  calculateDday(todo.end) === '마감일 지남'
                    ? 'expired'
                    : calculateDday(todo.end) >= 5
                      ? 'plenty'
                      : 'little'
                }
              >
                <div className="ListTitleWrap">
                  <span>{todo.title}</span>
                  <button
                    className="Delete"
                    onClick={(e) => removeTodo(todo.id, index, e)}
                  >
                    삭제
                  </button>
                </div>
                {expandedTodoIndex === index && (
                  <div className="TodoDetails">
                    <div>내용: {todo.content}</div>
                    <div>시작일: {formatDate(todo.start)}</div>
                    <div>종료일: {formatDate(todo.end)}</div>
                    <div>D-day: {calculateDday(todo.end)}</div>
                  </div>
                )}
              </li>
            ))}
          </>
        )}

        {selectedEvent && (
          <div className="modal">
            <div className='modal-content'>

              <h2>{selectedEvent.title}</h2>
              <p>내용: {selectedEvent.content}</p>
              <p>시작일: {formatDate(selectedEvent.start)}</p>
              <p>종료일: {formatDate(selectedEvent.end)}</p>
              <p>D-day: {calculateDday(selectedEvent.end)}</p>
              <div className='btnwrap'>
                <button className='Delete' onClick={() => { deleteEvent(selectedEvent.id) }}>삭제</button>
                <button onClick={() => setSelectedEvent(null)}>닫기</button>
              </div>
            </div>
          </div>
        )}
      </ul>
    </div>
  );
};