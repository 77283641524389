import { useState } from 'react';
import { db } from '../../../firebase';
import { collection, addDoc,doc,setDoc } from 'firebase/firestore';

const EventModal = ({ onClose }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [type, setType] = useState('personal'); // 'personal', 'team', 'company'
  const [showModal, setShowModal] = useState(true);
  const userDomain = sessionStorage.getItem('email').split('@')[1];
  const department = sessionStorage.getItem('department'); // 유저의 부서


  const closeModal = () => {
    if(typeof onClose === 'function') {
      onClose(); // 모달 닫기 함수를 호출
      console.log('if문실행')
    }
    onClose(); // 모달 닫기 함수를 호출
    console.log('클로즈모달실행')
    setShowModal(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let docRef; // 문서 참조를 저장할 변수
    const userId = sessionStorage.getItem('email');
    switch (type) {
      case 'personal':
        docRef = doc(db, `events/${userDomain}/${userId}`,`personalEvent_${new Date().toISOString()}`);
        break;
      case 'team':
        docRef = doc(db, `events/${userDomain}/${department}`,`departmentEvent_${new Date().toISOString()}`);
        break;
      case 'company':
        docRef = doc(db, `events/${userDomain}/company`,`companyEvent_${new Date().toISOString()}`);
        break;
      default:
        console.error("Invalid type");
        return;
    }
    
    try {
        // 문서 설정
        await setDoc(docRef, {
            title,
            description,
            location,
            start: new Date(start),
            end: new Date(end),
            type,
        }, { merge: true }); // 기존 문서에 머지
        closeModal();
    } catch (error) {
        console.error("Failed to add event:", error);
    }
};

  return (
    <div className='ModalBackdrop'>
  <form className='Modal' onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="제목"
          required
        />
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="내용"
          required
        />
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="장소"
          required
        />

        <input
          type="datetime-local"
          value={start}
          onChange={(e) => setStart(e.target.value)}
          required
        />
        <input
          type="datetime-local"
          value={end}
          onChange={(e) => setEnd(e.target.value)}
          required
        />
        <select value={type} onChange={(e) => setType(e.target.value)}>
          <option value="personal">개인</option>
          <option value="team">부서</option>
          <option value="company">전체</option>
        </select>
        <button type="submit">일정 추가</button>
        <button type="button" onClick={closeModal}>취소</button>
      </form>
    </div>
  );
};

export default EventModal;
