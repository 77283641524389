// 사용된 ID를 추적하기 위한 Set
const usedIds = new Set();

// 중복되지 않는 영어 알파벳으로 이루어진 고유 ID를 생성하는 함수
function generateUniqueEnglishId() {
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    let id;

    do {
        // 임의의 5글자 영어 알파벳 ID 생성
        id = "";
        for (let i = 0; i < 5; i++) {
            const randomIndex = Math.floor(Math.random() * alphabet.length);
            id += alphabet[randomIndex];
        }
    } while (usedIds.has(id)); // 중복 ID인 경우 다시 생성

    usedIds.add(id); // 사용된 ID 추가

    return id;
}

// 무작위 한국어 문장을 생성하는 함수
function getRandomKoreanSentence() {
    const sentences = [
        '안녕하세요. 반갑습니다.',
        '오늘 날씨가 좋네요.',
        '한국어 문장을 무작위로 생성합니다.',
        '이거 사실 무작위로 쏘는 데이터입니다 하하.',
        // 추가적인 무작위 문장들을 배열에 추가할 수 있습니다.
    ];

    const randomIndex = Math.floor(Math.random() * sentences.length);
    return sentences[randomIndex];
}

// 가짜 activity feeds 데이터 생성 함수
export function generateFakeActivityFeedsData() {
    if (!generateFakeActivityFeedsData.data) {
        // 데이터가 없을 때만 생성
        generateFakeActivityFeedsData.data = [];

        for (let i = 0; i < 100; i++) {
            const id = generateUniqueEnglishId(); // 중복되지 않는 영어 알파벳 ID 생성
            const date = new Date();
            date.setDate(date.getDate() - i);

            const timestamp = date.getTime();
            const content = getRandomKoreanSentence();

            generateFakeActivityFeedsData.data.push({ id, timestamp, content });
        }
    }

    return generateFakeActivityFeedsData.data;
}

// 가짜 mentions 데이터 생성 함수
export function generateFakeMentionsData() {
    if (!generateFakeMentionsData.data) {
        // 데이터가 없을 때만 생성
        generateFakeMentionsData.data = [];

        for (let i = 0; i < 100; i++) {
            const id = generateUniqueEnglishId(); // 중복되지 않는 영어 알파벳 ID 생성
            const date = new Date();
            date.setDate(date.getDate() - i);

            const timestamp = date.getTime();
            const content = getRandomKoreanSentence();

            generateFakeMentionsData.data.push({ id, timestamp, content });
        }
    }

    return generateFakeMentionsData.data;
}



// 가짜 Latest Post 데이터 생성 함수
export function generateFakeLatestPostData() {
    const latestPosts = [];

    for (let i = 0; i < 30; i++) {
        const id = generateUniqueEnglishId(); // 중복되지 않는 영어 알파벳 ID 생성
        const date = new Date();
        date.setDate(date.getDate() - i);

        const timestamp = date.getTime();
        const mention = getRandomKoreanSentence();

        // 원하는 날짜 형식으로 변환
        const formattedDate = `${date.getFullYear()}. ${String(date.getMonth() + 1).padStart(2, '0')}. ${String(date.getDate()).padStart(2, '0')}`;

        latestPosts.push({ date: formattedDate, id, mention, timestamp });
    }

    return latestPosts;
}

// 가짜 Schedule Post 데이터 생성 함수
export function generateFakeSchedulePostData() {
    const schedulePosts = [];

    for (let i = 0; i < 30; i++) {
        const date = new Date();
        date.setDate(date.getDate() + i); // Schedule Post는 미래 일자
        const scheduledDate = `${date.getFullYear()}. ${String(date.getMonth() + 1).padStart(2, '0')}. ${String(date.getDate()).padStart(2, '0')}`; // 예약 날짜

        const mention = getRandomKoreanSentence();
      
        schedulePosts.push({ scheduledDate, mention });
    }

    return schedulePosts;
}

