import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import * as XLSX from 'xlsx';

import '../css/ChartMaker.css';

export const ChartMaker = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(
    JSON.parse(localStorage.getItem('columns')) ||
    Array.from({ length: 10 }, (_, i) => `col${i + 1}`)
  );
  const [editingColumnIndex, setEditingColumnIndex] = useState(null);
  const [newColumnName, setNewColumnName] = useState('');
  const [sortPriority, setSortPriority] = useState([]);
  const [sortDirections, setSortDirections] = useState({});
  const [isChartModalOpen, setChartModalOpen] = useState(false);
  const [chartType, setChartType] = useState('bar');
  const [newColumn, setNewColumn] = useState('');

  useEffect(() => {
    localStorage.setItem('columns', JSON.stringify(columns));
  }, [columns]);

  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, 'table001'));
    const dbData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setData(dbData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdate = async (id, field, value) => {
    const docRef = doc(db, 'table001', id);
    await updateDoc(docRef, { [field]: value });

    setData((prevData) =>
      prevData.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: value };
        }
        return row;
      })
    );
  };

  const addColumn = () => {
    if (newColumn && !columns.includes(newColumn)) {
      setColumns([...columns, newColumn]);

      // 각 행에 새 열 추가
      const updatedData = data.map(row => ({
        ...row,
        [newColumn]: '' // 기본값을 빈 문자열로 설정
      }));
      setData(updatedData);

      setNewColumn(''); // 입력 필드 초기화
    }
  };

  const addRow = async () => {
    if (data.length < 20) {
      const docRef = await addDoc(collection(db, 'table001'), {
        ...columns.reduce((acc, col) => ({ ...acc, [col]: '' }), {}),
      });

      setData((prevData) => [
        ...prevData,
        { id: docRef.id, ...columns.reduce((acc, col) => ({ ...acc, [col]: '' }), {}) },
      ]);
    }
  };

  const startEditingColumn = (index) => {
    setEditingColumnIndex(index);
  };

  const saveEditedColumn = async () => {
    if (newColumnName && editingColumnIndex !== null) {
      const oldColumnName = columns[editingColumnIndex];
      const updatedColumns = columns.map((col, index) => {
        return index === editingColumnIndex ? newColumnName : col;
      });
      setColumns(updatedColumns);

      // 데이터를 새로운 컬럼 이름으로 업데이트
      const updatedData = data.map(row => {
        const newValue = row[oldColumnName];
        delete row[oldColumnName]; // 이전 컬럼 이름 삭제
        return { ...row, [newColumnName]: newValue };
      });
      setData(updatedData);

      // Firestore 문서를 새로운 컬럼 이름으로 업데이트
      updatedData.forEach(async (row) => {
        const docRef = doc(db, 'table001', row.id);
        await updateDoc(docRef, { [newColumnName]: row[newColumnName] });
      });

      setEditingColumnIndex(null);
      setNewColumnName('');
    }
  };


  // 열 삭제
  const deleteColumn = async (columnName) => {
    const updatedColumns = columns.filter(col => col !== columnName);
    setColumns(updatedColumns);
    const updatedData = data.map(row => {
      const newRow = { ...row };
      delete newRow[columnName];
      return newRow;
    });
    setData(updatedData);

    // 편집 중인 열 인덱스 초기화
    setEditingColumnIndex(null);
  };

  const renderColumnHeaders = () => {
    return columns.map((column, index) => (
      <th key={index} className={shouldHideColumn(column) ? 'no-value' : ''}>
        {editingColumnIndex === index ? (
          <div className='TableTitleWrap'>
            <input
              type="text"
              value={newColumnName}
              onChange={(e) => setNewColumnName(e.target.value)}
            />
            <div>

              <button onClick={saveEditedColumn}>Save</button>
              <button onClick={() => deleteColumn(column)}>Delete</button>
            </div>
          </div>
        ) : (
          <div>
            <span onClick={() => startEditingColumn(index)}>{column}</span>
            <div className="btnWrap1">
              <button className="sortup" onClick={() => toggleSort(column)}>▲</button>
              <button className="sortdown" onClick={() => toggleSort(column)}>▼</button>
            </div>
          </div>
        )}
      </th>
    ));
  };


  //행 삭제
  const deleteRow = async (id) => {
    await deleteDoc(doc(db, 'table001', id));
    setData((prevData) => prevData.filter((row) => row.id !== id));
  };

  const toggleSort = (column) => {
    const newSortDirections = { ...sortDirections };
    newSortDirections[column] = newSortDirections[column] === 'asc' ? 'desc' : 'asc';
    setSortDirections(newSortDirections);

    const newSortPriority = [column];
    setSortPriority(newSortPriority);
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const direction = newSortDirections[column];
      const aValue = a[column];
      const bValue = b[column];
      if (direction === 'asc') {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    setData(sortedData);
  };

  const shouldHideColumn = (column) => {
    return data.every((row) => !row[column]);
  };

  const renderTableRows = () => {
    return data.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {columns.map((column, colIndex) => (
          <td
            key={colIndex}
            className={`${shouldHideColumn(column) || (rowIndex !== 0 && (chartType === 'pie' || chartType === 'donut'))
                ? 'no-value'
                : ''
              }`}
          >
            <input
              type="text"
              className={rowIndex !== 0 && (chartType === 'pie' || chartType === 'donut') ? 'disabled-input' : ''}
              value={row[column] || ''}
              onChange={(e) => handleUpdate(row.id, column, e.target.value)}
              disabled={rowIndex !== 0 && (chartType === 'pie' || chartType === 'donut')}
            />
          </td>
        ))}
        <td>

          <button onClick={() => deleteRow(row.id)}>삭제</button>

        </td>
      </tr>
    ));
  };


  const renderChart = () => {
    if (chartType === 'pie' || chartType === 'donut') {
      // 데이터가 있는 컬럼만 필터링
      const hasDataColumns = columns.filter(column => data.some(row => row[column]));
    
      // 필터링된 컬럼을 기반으로 차트 데이터 생성
      const chartData = hasDataColumns.map(column => ({
        name: column,
        value: Number(data[0][column] || 0)
      }));
    
      // 각 섹션에 대한 랜덤 색상 생성
      const randomColors = chartData.map(() =>
        `#${Math.floor(Math.random() * 16777215).toString(16).padEnd(6, '0')}`
      );
    
      return (
        <ResponsiveContainer width="95%" height={400}>
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={chartType === 'donut' ? 70 : 0}
              outerRadius={100}
              label
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={randomColors[index]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      );
    }

    switch (chartType) {
      case 'bar':
        return (
          <ResponsiveContainer width="95%" height={400}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis />
              <Tooltip />
              <Legend />
              {columns.map((column, index) => {
                // 데이터가 있는 컬럼만 포함시킵니다.
                const hasData = data.some(row => row[column]);
                return hasData && (
                  <Bar
                    key={index}
                    dataKey={column}
                    name={column}
                    fill={`#${Math.floor(Math.random() * 16777215).toString(16).padEnd(6, '0')}`} // 유효한 색상 값 생성
                  />
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        );

      case 'horizontalBar':
        return (
          <ResponsiveContainer width="95%" height={400}>
            <BarChart layout="vertical" data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey={columns[0]} type="category" />
              <Tooltip />
              <Legend />
              {columns.slice(0).map((column, index) => {
                const hasData = data.some(row => row[column]);
                return hasData && (
                  <Bar
                    key={index}
                    dataKey={column}
                    name={column}
                    fill={`#${Math.floor(Math.random() * 16777215).toString(16).padEnd(6, '0')}`}
                  />
                );
              })}
            </BarChart>
          </ResponsiveContainer>

        );

      case 'line':
        return (

          <ResponsiveContainer width="95%" height={400}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis />
              <Tooltip />
              <Legend />
              {columns.map((column, index) => {
                const hasData = data.some(row => row[column]);
                return hasData && (
                  <Line
                    key={index}
                    dataKey={column}
                    name={column}
                    stroke={`#${Math.floor(Math.random() * 16777215).toString(16).padEnd(6, '0')}`} // 유효한 색상 값 생성
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>

        );

      default:
        return null;
    }

  };



  // 프린트용 차트 렌더링
  const renderPrintChart = () => {
    if (!isChartModalOpen) {
      return null;
    }
    if (chartType === 'pie' || chartType === 'donut') {
      const hasDataColumns = columns.filter(column => data.some(row => row[column]));
    
      const chartData = hasDataColumns.map(column => ({
        name: column,
        value: Number(data[0][column] || 0)
      }));
    
      const randomColors = chartData.map(() =>
        `#${Math.floor(Math.random() * 16777215).toString(16).padEnd(6, '0')}`
      );
    
      return (
        <ResponsiveContainer width={1000} height={400}>
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={chartType === 'donut' ? 70 : 0}
              outerRadius={130}
              label
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={randomColors[index]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      );
    }

    switch (chartType) {
      case 'bar':
        return (
          <ResponsiveContainer width={1000} height={500}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis />
              <Tooltip />
              <Legend />
              {columns.map((column, index) => {
                // 데이터가 있는 컬럼만 포함
                const hasData = data.some(row => row[column]);
                return hasData && (
                  <Bar
                    key={index}
                    dataKey={column}
                    name={column}
                    fill={`#${Math.floor(Math.random() * 16777215).toString(16).padEnd(6, '0')}`} // 유효한 색상 값 생성
                  />
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        );

      case 'horizontalBar':
        return (
          <ResponsiveContainer width={1000} height={400}>
            <BarChart layout="vertical" data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey={columns[0]} type="category" />
              <Tooltip />
              <Legend />
              {columns.slice(0).map((column, index) => {
                const hasData = data.some(row => row[column]);
                return hasData && (
                  <Bar
                    key={index}
                    dataKey={column}
                    name={column}
                    fill={`#${Math.floor(Math.random() * 16777215).toString(16).padEnd(6, '0')}`}
                  />
                );
              })}
            </BarChart>
          </ResponsiveContainer>

        );

      case 'line':
        return (

          <ResponsiveContainer width={1000} height={400}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis />
              <Tooltip />
              <Legend />
              {columns.map((column, index) => {
                const hasData = data.some(row => row[column]);
                return hasData && (
                  <Line
                    key={index}
                    dataKey={column}
                    name={column}
                    stroke={`#${Math.floor(Math.random() * 16777215).toString(16).padEnd(6, '0')}`} // 유효한 색상 값 생성
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>

        );

      default:
        return null;
    }

  };

  const handlePrint = () => {
    window.print();
  };

  const handleShare = () => {
    const url = window.location.href; // 현재 페이지의 URL을 가져옵니다.

    // 클립보드에 URL 복사
    navigator.clipboard.writeText(url).then(() => {
      alert("주소가 복사되었습니다!"); // 복사 성공 알림
    }).catch(err => {
      console.error('주소가 복사 중 오류가 발생했습니다.', err);
    });
  };

  const handleExcelExport = () => {
    // 데이터에서 빈 값이 아닌 열만 포함시킵니다.
    const filteredData = data.map(row => {
      const filteredRow = {};
      Object.keys(row).forEach(key => {
        if (row[key] !== '') {
          filteredRow[key] = row[key];
        }
      });
      return filteredRow;
    });

    const newData = filteredData.map(({ id, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(newData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "data.xlsx");
  };

  const toggleChartModal = () => {
    setChartModalOpen(prev => !prev); // 이전 상태를 반전시킵니다.
  };

  return (
    <div className="ChartMakerWrap">
      <h1 className="Title">Chart Maker</h1>

      <div className="btnWrap">
        <input
          className='addColInput'
          type="text"
          placeholder="열 이름"
          value={newColumn}
          onChange={(e) => setNewColumn(e.target.value)}
        />
        <button onClick={addColumn}>열 추가</button>
        <button onClick={addRow}>행추가</button>


      </div>
      <div className='btnWrap'>
        <select className='chartselect' value={chartType} onChange={(e) => setChartType(e.target.value)}>
          <option value="bar">Bar Chart</option>
          <option value="horizontalBar">Horizontal Bar Chart</option>
          <option value="line">Line Chart</option>
          <option value="donut">Donut Chart</option>
          <option value="pie">Pie Chart</option>
        </select>
        <button onClick={toggleChartModal}>
          {isChartModalOpen ? '차트숨기기' : '차트생성'}
        </button>
      </div>
      <div className='btnWrap'>
        <button onClick={handlePrint}>Print</button>
        <button onClick={handleShare}>Share</button>
        <button onClick={handleExcelExport}>Excel</button>
      </div>

      <div id="printArea">
        {isChartModalOpen && (
          <div className={`chart-container ${isChartModalOpen ? '' : 'hidden'}`}>
            <div className="sorted-columns">
              Sorted Columns: {sortPriority.map((col, index) => (
                <span key={index}>
                  {col.replace('-', '')} {sortDirections[col] === 'asc' ? '(▲)' : '(▼)'},{' '}
                </span>
              ))}
            </div>
            {renderChart()}

          </div>
        )}
     {/* 프린트용 차트 */}
     <div className="print-chart-container">
        {renderPrintChart()}
        </div>
   

        <div className='table-container'>
          <table>
            <thead>
              <tr>
                {renderColumnHeaders()}
              </tr>
            </thead>
            <tbody>
              {renderTableRows()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ChartMaker;