import { useState, useEffect, useMemo } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ko from 'date-fns/locale/ko';
import '../../css/ERPCalendar.css';
import { collection, query, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import useHolidayData from '../../components/HoliDays';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { isSameDay } from 'date-fns';

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: { 'ko': ko },
});

const formats = {
  dayFormat: (date, culture, localizer) => localizer.format(date, 'EEEE', culture),
  weekdayFormat: (date, culture, localizer) => localizer.format(date, 'eeeeee', culture),
};

// CustomToolbar 컴포넌트 내에 애니메이션 로직 추가
const CustomToolbar = ({ date, onNavigate, setAnimationClass }) => {
  const navigate = (action) => {
    setAnimationClass(action === 'NEXT' ? 'slide-left' : 'slide-right');
    setTimeout(() => {
      onNavigate(action);
    }, 300); // 애니메이션 지속 시간과 일치해야 함
  };

  return (
    <div className='toolbar-container'>
      <span>{format(date, 'yyyy년 MM월', { locale: ko })}</span>
      <div>
        <button onClick={() => navigate('PREV')}>▴</button>
        <button onClick={() => navigate('NEXT')}>▾</button>
      </div>
    </div>
  );
};

const EventListModal = ({ isOpen, events, onClose, onEventSelect }) => {
  if (!isOpen) return null;
  const closeModal = (e) => {
    if (e.target.id === "modalBackdrop") {
      onClose();
    }
    if (e.currentTarget === e.target) {
      onClose();
    }
  };

  return (
    <div className='ModalBackdrop' onClick={closeModal}>
      <div className="modal-content">
        <h2>이벤트 목록</h2>
        <ul>
          {events.map((event, index) => (
            <li key={index} onClick={() => onEventSelect(event)}>
              {event.title}
            </li>
          ))}
        </ul>
        <button onClick={onClose}>닫기</button>
      </div>
    </div>
  );
};

const EventDetailModal = ({ isOpen, event, onClose }) => {
  if (!isOpen || !event) return null;
  const closeModal = (e) => {
    if (e.target.id === "modalBackdrop") {
      onClose();
    }
    if (e.currentTarget === e.target) {
      onClose();
    }
  };

  // 이벤트의 시작 시간과 종료 시간을 포맷팅합니다.
  const formattedStart = format(event.start, 'yyyy년 MM월 dd일 HH:mm');
  const formattedEnd = format(event.end, 'yyyy년 MM월 dd일 HH:mm');

  return (
    <div className='ModalBackdrop' onClick={closeModal}>
      <div className="modal-content">
        <h2>{event.title}</h2>
        <p>{event.description}</p>
        <p>{event.location}</p>
        <p>시작 시간: {formattedStart}</p>
        <p>종료 시간: {formattedEnd}</p>
        <button onClick={onClose}>닫기</button>
      </div>
    </div>
  );
};

const CalendarComponent = () => {
  const [date, setDate] = useState(new Date());
  const [animationClass, setAnimationClass] = useState('');
  const [events, setEvents] = useState([]); // 일정
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [holidays, setHolidays] = useState([]); // 공휴일 데이터를 위한 상태 추가

  const auth = getAuth();
  const navigate = useNavigate();
  useEffect(() => {
    //Auth 상태 감지 리스너
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/erp/login');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  // 공휴일 데이터를 가져오기
  const holidayData = useHolidayData();

  useEffect(() => {
    setHolidays(holidayData);
  }, [holidayData]);

  const allEvents = useMemo(() => {
    const mergedEvents = [...events, ...holidays];
    const uniqueEvents = Array.from(new Set(mergedEvents.map(event => event.id)))
      .map(id => mergedEvents.find(event => event.id === id));
    return uniqueEvents;
  }, [events, holidays]);

  useEffect(() => {
    const storedEmail = sessionStorage.getItem('email');
    setCurrentUserEmail(storedEmail);
  }, []);

  useEffect(() => {
    // currentUserEmail이 유효한지 확인
    if (currentUserEmail) {
      const domain = currentUserEmail.split('@')[1];
      const userId = sessionStorage.getItem('email');
      const eventsCollectionPathP = `events/${domain}/${userId}`;
      const eventsCollectionPathD = `events/${domain}/${sessionStorage.getItem('department')}`;
      const eventsCollectionPathC = `events/${domain}/company`;

      // 각 컬렉션에 대한 쿼리 생성
      const queries = [
        query(collection(db, eventsCollectionPathP)),
        query(collection(db, eventsCollectionPathD)),
        query(collection(db, eventsCollectionPathC)),
      ];

      // 모든 쿼리를 실행하고 결과를 병합
      Promise.all(queries.map(q => getDocs(q))).then(snapshots => {
        const mergedEvents = snapshots.flatMap(snapshot =>
          snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            start: doc.data().start.toDate(),
            end: doc.data().end.toDate(),
          })),
        );
        setEvents(mergedEvents);
        console.log(mergedEvents);
      }).catch(error => {
        console.error("Error loading events:", error);
      });
    } else {
      console.log("currentUserEmail is not defined.");
    }
  }, [currentUserEmail, db]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationClass('');
    }, 300);
    return () => clearTimeout(timer);
  }, [date]);

  const onNavigate = (action) => {
    let newDate = new Date(date);
    if (action === 'NEXT') {
      newDate.setMonth(newDate.getMonth() + 1);
    } else if (action === 'PREV') {
      newDate.setMonth(newDate.getMonth() - 1);
    }
    setDate(newDate);
  };

  const { views } = useMemo(() => ({
    views: {
      month: true
    }
  }), []);

  const [selectedEvent, setSelectedEvent] = useState(null); // 선택된 이벤트 상태

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    setIsListModalOpen(false);
    setIsDetailModalOpen(true);
  };

  const handleShowMore = (moreEvents) => {
    setSelectedEvents(moreEvents);
    setIsListModalOpen(true);
    setIsDetailModalOpen(false);
  };

  const handleEventSelectFromList = (event) => {
    setSelectedEvent(event);
    setIsListModalOpen(false);
    setIsDetailModalOpen(true);
  };

  const eventStyleGetter = (event) => {
    if (event.type === 'holiday') {
      return {
        style: {
          backgroundColor: 'rgba(255,0,0,1)',
          color: 'black',
          fontWeight: 'bold',
          border: 'none',
          opacity: '1',
          fontSize: '12px',
        }
      };
    }
    return {};
  };

  const dayPropGetter = (date) => {
    if (isSameDay(date, new Date())) {
      return {
        className: 'todayHighlight'
      };
    }
    return {};
  };

  return (
    
    <div className="calendar-container">
      <div className='calendar-Wrap'>
        <CustomToolbar date={date} onNavigate={onNavigate} setAnimationClass={setAnimationClass} />
        <div className={`calendar-content ${animationClass}`}>
          <Calendar
            localizer={localizer}
            events={allEvents}
            eventPropGetter={eventStyleGetter}
            startAccessor="start"
            endAccessor="end"
            date={date}
            formats={formats}
            views={views}
            dayPropGetter={dayPropGetter}
            culture="ko"
            style={{ height: '80%' }} // 높이를 100%로 설정
            onSelectEvent={handleEventSelect}
            messages={{
              showMore: total => `+${total} 더보기`
            }}
            onShowMore={handleShowMore}
            components={{
              toolbar: () => <></>, // Toolbar 비활성화
            }}
            onEventSelect={handleEventSelectFromList}
          />
          <EventListModal
            isOpen={isListModalOpen}
            events={selectedEvents}
            onClose={() => setIsListModalOpen(false)}
            onEventSelect={handleEventSelectFromList}
          />
          <EventDetailModal
            isOpen={isDetailModalOpen}
            event={selectedEvent}
            onClose={() => setIsDetailModalOpen(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarComponent;
