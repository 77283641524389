import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import '../../css/Board.css';
import { HeaderMenuERP } from '../../components/HeaderMenu_ERP';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';


export const Board = () => {
  const [posts, setPosts] = useState([]);
  const [newTitle, setNewTitle] = useState('');
  const [newContent, setNewContent] = useState('');
  const [userDomain, setUserDomain] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();
  useEffect(() => {
    //Auth 상태 감지 리스너
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/erp/login');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  useEffect(() => {
    const userEmail = sessionStorage.getItem('email');
    const domain = userEmail.split('@')[1];
    setUserDomain(domain);
    fetchPosts(domain);
  }, []);

  
  useEffect(() => {
    const userEmail = sessionStorage.getItem('email');
    if (userEmail) {
      const domain = userEmail.split('@')[1];
      setUserDomain(domain);
      fetchPosts(domain);
    }
  }, []);

  const fetchPosts = async (domain) => {
    // 도메인을 식별할 수 있는 문서에서 'posts' 서브컬렉션을 참조합니다.
    const postsRef = collection(db, "domains", domain, "posts");
    const querySnapshot = await getDocs(postsRef);
    const fetchedPosts = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setPosts(fetchedPosts);
  };

  const handleAddPost = async () => {
    if (!newTitle || !newContent) {
      alert('제목과 내용을 입력해주세요.');
      return;
    }
    const postsRef = collection(db, "domains", userDomain, "posts");
    await addDoc(postsRef, {
      title: newTitle,
      content: newContent,
      createdAt: new Date(),
      name : sessionStorage.getItem('username'),
      position : sessionStorage.getItem('position')
    });
    fetchPosts(userDomain);
    setNewTitle('');
    setNewContent('');
  };

  const handleDeletePost = async (postId) => {
    await deleteDoc(doc(db, "domains", userDomain, "posts", postId));
    fetchPosts(userDomain); 
  };


  return (
    <div className="ERP-Wrap">
      <HeaderMenuERP />
      <div className="board-container">
        <h2>게시판</h2>
        <div className="post-form">
          <input
            className="post-input"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            placeholder="제목"
          />
          <textarea
            className="post-textarea"
            value={newContent}
            onChange={(e) => setNewContent(e.target.value)}
            placeholder="내용"
          ></textarea>
          <button className="post-submit" onClick={handleAddPost}>게시글 추가</button>
        </div>

        <div className='postWrap'>
          {posts.map((post) => (
            <div key={post.id} className="post">
              <h3>{post.title}</h3>
              <p>{post.content}</p>
              <p>{post.name}</p>
              <p>{post.position}</p>
              <small>작성일: {post.createdAt.toDate().toLocaleString()}</small>
              <div className="post-actions">
                <button onClick={() => handleDeletePost(post.id)}>삭제</button>
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};