import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { auth } from '../../firebase';
import "../css/HeaderMenu.css";
import { Logout } from './logout';
import { LoginModal } from '../pages/Login';

const HeaderMenu = ({ selectedMode, isVisible }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [loggedInUserId, setLoggedInUserId] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setLoggedInUserId(user.uid);
      } else {
        setLoggedInUserId(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const closeMenuOnOutsideClick = (e) => {
      if (!menuOpen) return;
      if (e.target.closest('.header-menu')) return;
      closeMenu();
    };

    document.addEventListener('click', closeMenuOnOutsideClick);
    return () => {
      document.removeEventListener('click', closeMenuOnOutsideClick);
    };
  }, [menuOpen]);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  if (location.pathname.startsWith('/projects/erp') || !isVisible) {
    return null;
  }

  const isHiddenPage = location.pathname === '/' || location.pathname === '/userinfo' || location.pathname === '/chartmaker'
    || location.pathname === '/login' 
    || location.pathname === '/todolist' || location.pathname.startsWith('/learninghub')|| location.pathname.startsWith('/projects');

  const isTicketPage = location.pathname === '/ticket';

  return (
    <header id='header' className={`header ${location.pathname === '/projects/erp' ? 'light' : ''} ${selectedMode === 'darkmode' ? 'darkmode' : 'lightmode'}`}>
      <div className="header-top">
        {loggedInUserId && !isHiddenPage && (
          <>
            <Link to="/userinfo" className="userlink">내 정보</Link>
            <Logout />
          </>
        )}
        {!loggedInUserId && !isHiddenPage && <button className='LoginBtn' onClick={toggleLoginModal}>로그인</button>}
      </div>

      {!isTicketPage && (
        <div className={`header-menu ${menuOpen ? 'open' : ''}`}>
          <Link className="logo" to="/" onClick={closeMenu}></Link>
          {/* <div className="topNav">
            <Link to="/webgame" onClick={closeMenu}>웹 게임</Link>
            <Link to="/weather" onClick={closeMenu}>날씨정보</Link>
            <Link to="/pokemon" onClick={closeMenu}>포켓몬 도감</Link>
            <a href="/erp" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>ERP</a>
          </div> */}
          <GiHamburgerMenu onClick={toggleMenu} size="1.5em" className='hamberger' />

          {menuOpen && (
            <div className="menu">
              {/* <Link className="logo2" to="/" onClick={closeMenu}></Link>
              <Link to="/webgame" onClick={closeMenu}>웹 게임</Link>
              <Link to="/weather" onClick={closeMenu}>날씨정보</Link>
              <Link to="/pokemon" onClick={closeMenu}>포켓몬 도감</Link>
              <a href="/erp" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>ERP</a> */}
              <Link className="logo2" to="/" onClick={closeMenu}></Link>
              <Link to="/" onClick={closeMenu}>Home</Link>
              <Link to="/projects" onClick={closeMenu}>Projects</Link>
              <Link to="/learninghub" onClick={closeMenu}>Learning Hub</Link>
            </div>

          )}
        </div>
      )}
      {showLoginModal && <LoginModal onClose={toggleLoginModal} />}
    </header>
  );
}

export default HeaderMenu;
