import { HeaderMenuERP } from "../../components/HeaderMenu_ERP";

export const Mail = () => {

    return (
      <div className="ERP-Wrap">
      <HeaderMenuERP/>
메일
      </div>
    );
  };
  