import { HeaderMenuERP } from "../../components/HeaderMenu_ERP";

export const Government_Support = () => {

    return (
      <div className="ERP-Wrap">
      <HeaderMenuERP/>
정부지원사업
      </div>
    );
  };
  