import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { addDoc, collection, getDocs, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; 
import { db,storage } from '../../firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../css/LHCreatePage.css';

const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-header" defaultValue="">
      <option value="1" />
      <option value="2" />
      <option value="3" />
      <option value="" />
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-strike" />
    <button className="ql-list" value="ordered" />
    <button className="ql-list" value="bullet" />
    <button className="ql-code-block" /> {/* 코드 블록 버튼 추가 */}
    <select className="ql-color" />
    <select className="ql-background" />
    <button className="ql-link" />
    <button className="ql-image" />
    <button className="ql-video" />
    <button className="ql-blockquote" />
    <button className="ql-clean" />
    <button onClick={() => document.getElementById('file-upload').click()}>📁</button>
  </div>
);

export const CreatePage = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [file, setFile] = useState(null); 
  const [fileName, setFileName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "tags"));
        const tagData = querySnapshot.docs.map(doc => doc.data().name);
        setTags(tagData);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchTags();
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  };

  const handleTagChange = (tag) => {
    setSelectedTags(prevTags =>
      prevTags.includes(tag)
        ? prevTags.filter(t => t !== tag)
        : [...prevTags, tag]
    );
  };

  const handleCreate = async () => {
    if (!title || !content) {
      alert("제목과 내용을 모두 입력하세요.");
      return;
    }

    let fileUrl = '';

    if (file) {
      const fileRef = ref(storage, `files/${file.name}`);
      await uploadBytes(fileRef, file);
      fileUrl = await getDownloadURL(fileRef);
    }

    try {
      await addDoc(collection(db, "posts"), {
        title,
        content,
        tags: selectedTags,
        date: serverTimestamp(),
        fileUrl,
        fileName,
      });
      alert("게시물이 성공적으로 저장되었습니다!");
      navigate(`/learninghub`);
    } catch (error) {
      console.error("Error adding document:", error);
      alert("게시물 저장 중 오류가 발생했습니다.");
    }
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
    clipboard: {
      matchVisual: false,  // 코드 블록과 일반 텍스트를 구분해서 저장하도록 설정
    },
  };

  return (
    <div className="LHCreate_Container">
      <Link to="/learninghub" className='Title'>러닝허브</Link>
      <input
        type="text"
        placeholder="제목"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <div className='quillwrap'>
        <CustomToolbar />
        <ReactQuill
          value={content}
          onChange={setContent}
          modules={modules}
          theme="snow"
          placeholder="내용을 입력하세요"
        />
      </div>
      
      <input
        type="file"
        id="file-upload"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <div className="file-info">
        {fileName && <span>업로드 된 파일: {fileName}</span>}
      </div>
      
      <div className="tags-selection">
        <h3>tags : </h3>
        {tags.map((tag) => (
          <label key={tag}>
            <input
              type="checkbox"
              checked={selectedTags.includes(tag)}
              onChange={() => handleTagChange(tag)}
            />
            {tag}
          </label>
        ))}
      </div>

      <button onClick={handleCreate} className='create-btn'>작성</button>
    </div>
  );
};
