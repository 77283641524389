import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../../firebase';
import { collection, query, where, getDoc, updateDoc, arrayUnion, setDoc, doc, onSnapshot } from 'firebase/firestore';
import { HeaderMenuERP } from '../../components/HeaderMenu_ERP';
import { useLocation } from 'react-router-dom';
import '../../css/Messenger.css';

export const Messenger = () => {
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [lastMessages, setLastMessages] = useState({});
  const messagesEndRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const storedEmail = sessionStorage.getItem('email');
    setCurrentUserEmail(storedEmail);

    const state = location.state;
    if (state && state.user) {
      const fullEmail = state.user;
      setSelectedUserEmail(fullEmail);
      const selectedUserObj = users.find(user => `${user.email}@${user.domain}` === fullEmail);
      setSelectedUser(selectedUserObj ? selectedUserObj.fullName : fullEmail);
    }
  }, [location.state, users]);

  useEffect(() => {
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const fetchUsers = async () => {
      const domain = currentUserEmail.split('@')[1];
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('domain', '==', domain), where('email', '!=', currentUserEmail));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const usersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })).filter((user) => user.email !== currentUserEmail.split('@')[0]);
        setUsers(usersData);
      });

      return () => unsubscribe();
    };

    if (currentUserEmail) {
      fetchUsers();
    }
  }, [currentUserEmail]);

  useEffect(() => {
    const fetchLastMessages = async () => {
      const lastMessagesData = {};
      await Promise.all(
        users.map(async (user) => {
          const sessionID = [currentUserEmail, `${user.email}@${user.domain}`].sort().join('_');
          const sessionRef = doc(db, 'chatSessions', sessionID);
          const docSnap = await getDoc(sessionRef);
          if (docSnap.exists()) {
            const sessionData = docSnap.data();
            lastMessagesData[`${user.email}@${user.domain}`] = sessionData.lastMessage || null;
          } else {
            lastMessagesData[`${user.email}@${user.domain}`] = null;
          }
        })
      );
      setLastMessages(lastMessagesData);
    };

    if (users.length > 0 && currentUserEmail) {
      fetchLastMessages();
    }
  }, [currentUserEmail, users]);

  useEffect(() => {
    if (!currentUserEmail || !selectedUserEmail) return;

    const sessionID = [currentUserEmail, selectedUserEmail].sort().join('_');
    const sessionRef = doc(db, 'chatSessions', sessionID);

    const unsubscribe = onSnapshot(sessionRef, (doc) => {
      if (doc.exists()) {
        const sessionData = doc.data();
        setMessages(sessionData.messages || []);
        setLastMessages((prev) => ({
          ...prev,
          [selectedUserEmail]: sessionData.lastMessage || null,
        }));
      } else {
        setMessages([]);
        setLastMessages((prev) => ({
          ...prev,
          [selectedUserEmail]: null,
        }));
      }
    });

    const resetMessengerCount = async () => {
      const recipientEmail = currentUserEmail.split('@')[0];
      const recipientDomain = currentUserEmail.split('@')[1];
      const recipientDocRef = doc(db, 'users', `${recipientEmail}@${recipientDomain}`);
      const recipientDoc = await getDoc(recipientDocRef);
      if (recipientDoc.exists()) {
        const notifications = recipientDoc.data().notifications || {};
        notifications.messenger = 0;
        await updateDoc(recipientDocRef, { notifications });
      }
    };

    resetMessengerCount();

    return () => unsubscribe();
  }, [currentUserEmail, selectedUserEmail]);

  const renderUserList = (users, setSelectedUserEmail, lastMessages) => {
    return (
      <div className="user-list">
        {users.map((user) => (
          <div className='user'
            key={user.id}
            onClick={() => {
              const fullEmail = `${user.email}@${user.domain}`;
              setSelectedUserEmail(fullEmail);
              setSelectedUser(user.fullName);
            }}
            style={{
              backgroundColor: selectedUserEmail === `${user.email}@${user.domain}` ? 'rgba(0, 0, 0, 0.2)' : ''
            }}
          >
            <div>
              {user.fullName} - {user.department}, {user.position}
            </div>
            {lastMessages[`${user.email}@${user.domain}`] && (
              <div className='lastMsg'>
                마지막 메시지: {lastMessages[`${user.email}@${user.domain}`].content}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const sendMessage = async () => {
    if (!selectedUserEmail || !message) {
      alert('먼저 유저를 선택하세요.');
      return;
    }

    const sessionId = [currentUserEmail, selectedUserEmail].sort().join('_');
    const sessionRef = doc(db, 'chatSessions', sessionId);
    const now = new Date();

    try {
      const docSnap = await getDoc(sessionRef);

      const newMessage = {
        from: currentUserEmail,
        to: selectedUserEmail,
        content: message,
        timestamp: now.toISOString(),
      };

      if (docSnap.exists()) {
        await updateDoc(sessionRef, {
          messages: arrayUnion(newMessage),
          lastMessage: newMessage,
        });
      } else {
        await setDoc(sessionRef, {
          participants: [currentUserEmail, selectedUserEmail],
          messages: [newMessage],
          lastMessage: newMessage,
        });
      }

      const recipientEmail = selectedUserEmail.split('@')[0];
      const recipientDomain = selectedUserEmail.split('@')[1];
      const recipientDocRef = doc(db, 'users', `${recipientEmail}@${recipientDomain}`);
      const recipientDoc = await getDoc(recipientDocRef);
      if (recipientDoc.exists()) {
        const notifications = recipientDoc.data().notifications || {};
        notifications.messenger = (notifications.messenger || 0) + 1;
        await updateDoc(recipientDocRef, { notifications });
      }

      setMessage('');
    } catch (error) {
      console.error("메시지 보내기 실패:", error);
    }
  };

  return (
    <div className="ERP-Wrap">
      <HeaderMenuERP />
      <div className='Messenger-Wrap'>
        <div className={`user-list ${selectedUserEmail ? 'hidden' : ''}`}>
          {renderUserList(users, setSelectedUserEmail, lastMessages)}
        </div>

        <div className={`Messages-Wrap ${selectedUserEmail ? '' : 'hidden'}`}>
          <div className="header">
            <button onClick={() => setSelectedUserEmail('')} className="back-button">←</button>
            <span className="selected-user">{selectedUser}</span>
          </div>
          <div className='Msg'>
            {messages.map((msg, index) => (
              <div key={index} className={`message-container ${msg.from === currentUserEmail ? 'my-message' : 'other-message'}`}>
                <strong>{msg.from === currentUserEmail ? '나 : ' : `${selectedUser} : `}</strong>
                {msg.content}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className='sending-wrap'>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="메시지를 입력하세요"
              onKeyPress={handleOnKeyPress}
            />
            <button className="material-symbols-outlined" onClick={sendMessage}>
              send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
