import { HeaderMenuERP } from "../../components/HeaderMenu_ERP";

export const Forms = () => {

    return (
      <div className="ERP-Wrap">
      <HeaderMenuERP/>
업무용 서식
      </div>
    );
  };
  