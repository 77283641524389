import React, { useEffect, useState } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebase';
import '../css/Login.css';

// URL에서 쿼리 파라미터를 추출하는 함수
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function Login() {
  const query = useQuery(); // useQuery 훅을 사용
  const service = query.get('service'); // 'service' 쿼리 파라미터를 가져옴
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = event => {
    event.preventDefault();
    const email = `${username}@test001.com`;
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        console.log("로그인페이지 성공");
        navigate(`/${service}`); // URL 파라미터에 따라 해당 서비스로 리디렉션
      })
      .catch(error => {
        console.log("로그인페이지 실패");
        if (error.code === 'auth/invalid-login-credentials') {
          setError('아이디와 비밀번호를 다시 확인해 주세요.');
        } else {
          setError('로그인 중 예기치 못한 오류가 발생했습니다. 다시 시도해 주세요.');
        }
      });
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h1>{`${service} 로그인`}</h1>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin}>
        <div className='input-container'>
          <span className="material-symbols-outlined">person</span>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="아이디"
              required
            />
        </div>
            <div className='input-container'>
              <span className="material-symbols-outlined">lock</span>
                        <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="패스워드"
              required
                        />
            </div>
          <button type="submit">로그인</button>
        </form>
      </div>
    </div>
  );
}


export function LoginModal({ onLoginSuccess, onClose }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showSignupModal, setShowSignupModal] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!showSignupModal && event.target.classList.contains('login-modal')) {
        onClose();
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [onClose, showSignupModal]);

  const handleLogin = event => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, `${username}@test001.com`, password)
      .then(() => {
        setError('');
        if (typeof onLoginSuccess === 'function') {
          onLoginSuccess();
        }
        onClose();
      })
      .catch(error => {
        setError('아이디 또는 패스워드를 다시 확인해주세요');
      });
  };
  
  const openSignupModal = () => {
    setShowSignupModal(true);
  };

  const closeSignupModal = () => {
    setShowSignupModal(false);
  };

  return (
    <div className="login-modal" onClick={onClose}>
      {!showSignupModal && (
        <div className="login-modal-content" onClick={(e) => e.stopPropagation()}>
                
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleLogin}>
          <div>
            <span className="material-symbols-outlined">person</span>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="test001"
                    required
                  />
          </div>
            <div>
            <span className="material-symbols-outlined">lock</span>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="test001"
                required
              />
            </div>
            <button className='LoginBtn' type="submit" >로그인</button>
          </form>
          <div className='signupwrap'>
            아이디가 없으신가요 ?
            <button onClick={openSignupModal} className='signup'>회원가입</button>
          </div>
        </div>
      )}

      {showSignupModal && <SignupModal onBackToLogin={closeSignupModal} />}
    </div>
  );
}

function SignupModal({ onBackToLogin }) {
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [signupError, setSignupError] = useState('');

  const handleSignup = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, `${newUsername}@test001.com`, newPassword)
      .then(() => {
        onBackToLogin();
      })
      .catch(error => {
        if (error.code === 'auth/weak-password') {
          setSignupError('비밀번호를 최소 6자리 이상으로 설정하세요.');
        } else if (error.code === 'auth/email-already-in-use') {
          setSignupError('이미 사용중인 아이디입니다.');
        } else {
          setSignupError('회원가입 실패: ' + error.message);
        }
      });
    }

  return (
    <div className="signup-modal" onClick={(e) => { e.stopPropagation(); onBackToLogin(); }}>
      <div className="signup-modal-content" onClick={(e) => e.stopPropagation()}>
        {signupError && <p className="error-message">{signupError}</p>}
        <form onSubmit={handleSignup}>
          <input
            type="text"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            placeholder="새 아이디"
            required
          />
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="새 비밀번호"
            required
          />
          <button type="submit">회원가입</button>
        </form>
      </div>
    </div>
  );
}