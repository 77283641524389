export const NotFound = () => {
    return (
        <>
            <div className="NotFound">

                <h1 className="Title">잘못된 접근입니다.</h1>

            </div>
        </>
    )
}