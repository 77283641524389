import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, where, addDoc, doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import '../css/EdmsPages.css';
import { getAuth } from 'firebase/auth';

// 기안문 작성
export const DraftCreationPage = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [approvalLine, setApprovalLine] = useState([]);
    const [currentUserDomain, setCurrentUserDomain] = useState('');
    const [employees, setEmployees] = useState([]);
    const [category, setCategory] = useState('일반'); // 카테고리 상태 추가
    const [startDate, setStartDate] = useState(''); // 연차 시작일
    const [endDate, setEndDate] = useState(''); // 연차 종료일

    const positionsOrder = {
        인턴: 0, 사원: 1, 주임: 2, 대리: 3, 과장: 4, 차장: 5, 부장: 6, 팀장: 7, 실장: 8, 본부장: 9,
        이사: 10, 상무: 11, 전무: 12, 부사장: 13, 사장: 14, 부회장: 15, 회장: 16, 관리자: 17,
    };

    useEffect(() => {
        const emailFromSession = sessionStorage.getItem('email');
        const domain = emailFromSession?.split('@')[1];
        setCurrentUserDomain(domain);

        fetchUsersByDomain();
    }, []);

    const fetchUsersByDomain = async () => {
        const emailFromSession = sessionStorage.getItem('email');
        const currentUserDomain = emailFromSession?.split('@')[1];

        if (!currentUserDomain) {
            console.error('Domain 정보를 찾을 수 없습니다.');
            return;
        }
        const q = query(collection(db, 'users'), where('domain', '==', currentUserDomain));

        try {
            const employeesSnapshot = await getDocs(q);
            const employeeList = employeesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setEmployees(employeeList);
        } catch (error) {
            console.error('직원 목록을 불러오는 중 오류 발생:', error);
        }
    };

    const handleAddToApprovalLine = (userId) => {
        if (approvalLine.includes(userId)) {
            alert('이미 결재라인에 추가된 사용자입니다.');
            return;
        }

        const userToAdd = employees.find(user => user.id === userId);
        if (!userToAdd) return;

        if (userToAdd.position === '관리자') {
            setApprovalLine([...approvalLine, userId]);
            return;
        }

        const newApprovalLine = [...approvalLine];
        let added = false;

        for (let i = 0; i < newApprovalLine.length; i++) {
            const userInLine = employees.find(user => user.id === newApprovalLine[i]);
            if (positionsOrder[userToAdd.position] < positionsOrder[userInLine.position]) {
                newApprovalLine.splice(i, 0, userId);
                added = true;
                break;
            }
        }
        if (!added) {
            newApprovalLine.push(userId);
        }
        setApprovalLine(newApprovalLine);
    };

    const handleRemoveFromApprovalLine = (userId) => {
        setApprovalLine(approvalLine.filter(id => id !== userId));
    };

    const handleSubmitDraft = async () => {
        if (!title || !content || approvalLine.length === 0) {
            alert('제목, 내용, 결재라인을 모두 입력해주세요.');
            return;
        }

        const storedEmail = sessionStorage.getItem('email');
        const userRef = doc(db, "users", storedEmail);
        const userDoc = await getDoc(userRef);
        const userName = userDoc.data().fullName;
        const userPosition = userDoc.data().position;

        try {
            await addDoc(collection(db, 'drafts'), {
                title,
                content,
                approvalLine,
                currentApprovalStep: 0,
                status: 'pending',
                createdAt: new Date(),
                authorName: userName,
                authorPosition: userPosition,
                authorEmail: storedEmail,
                category,
                startDate,
                endDate,
            });

            for (const approver of approvalLine) {
                const approverDocRef = doc(db, 'users', approver);
                const approverDoc = await getDoc(approverDocRef);
                const notifications = approverDoc.data().notifications || {};
                notifications.requestBox = (notifications.requestBox || 0) + 1;
                notifications.edms = (notifications.edms || 0) + 1;

                await updateDoc(approverDocRef, {
                    notifications
                });
            }

            setTitle('');
            setContent('');
            setApprovalLine([]);
            setStartDate('');
            setEndDate('');
            alert('기안문이 제출되었습니다.');
        } catch (error) {
            console.error("기안문 제출 중 오류 발생:", error);
            alert('기안문 제출 중 오류가 발생했습니다.');
        }
    };

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setCategory(selectedCategory);

        if (selectedCategory === '연차') {
            setTitle('연차 신청');
            setContent('연차를 신청합니다.');
        } else {
            setTitle('');
            setContent('');
        }
    };

    return (
        <div className='draft_create_page'>
            <h2>기안문 작성</h2>
            <div className='categoryWrap'>
                <label>카테고리:</label>
                <select value={category} onChange={handleCategoryChange}>
                    <option value="일반">일반</option>
                    <option value="연차">연차</option>
                    {/* 추후 다른 카테고리 추가 */}
                </select>
            </div>
            {category === '연차' && (
                <div className='dateWrap'>
                    <label>시작일:</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                    />
                    <label>종료일:</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        required
                    />
                </div>
            )}
            <div className='titleWrap'>
                <label>제목:</label>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
            </div>
            <div className='detailWrap'>
                <label>내용:</label>
                <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    required
                ></textarea>
            </div>
            <div className='approvalLine'>
                <h3>결재라인</h3>
                <ul>
                    {employees.map(employee => (
                        <li key={employee.id} onClick={() => handleAddToApprovalLine(employee.id)}>
                            {employee.fullName} {employee.position}
                        </li>
                    ))}
                </ul>


            </div>
<div className='selectedApprovalWrap'>
    
                    <h3>선택된 결재라인</h3>
                    {approvalLine.map(userId => (
                        <div className='selectedApproval' key={userId} onClick={() => handleRemoveFromApprovalLine(userId)}>
                            {employees.find(user => user.id === userId)?.fullName} - 클릭하여 제거
                        </div>
                    ))}
    
</div>
            <button onClick={handleSubmitDraft}>기안문 제출</button>
        </div>
    );
};
// 결재요청함
export const ApprovalRequests = () => {
    const [requests, setRequests] = useState([]);

    const fetchApprovalRequests = async () => {
        const currentUserEmail = sessionStorage.getItem('email');
        if (!currentUserEmail) {
            console.error('No email in session storage');
            return;
        }

        const draftsRef = collection(db, 'drafts');
        const q = query(draftsRef);

        try {
            const querySnapshot = await getDocs(q);
            const fetchedRequests = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            })).filter((request) => {
                const currentStep = request.currentApprovalStep || 0;
                return request.approvalLine[currentStep] === currentUserEmail;
            });

            setRequests(fetchedRequests);
        } catch (error) {
            console.error('Error fetching approval requests:', error);
        }
    };

    useEffect(() => {
        fetchApprovalRequests();
    }, []); // 빈 배열을 의존성 배열로 사용하여 컴포넌트가 마운트될 때 한 번만 실행

    const handleApprove = async (request) => {
        const currentUserEmail = sessionStorage.getItem('email');
        const currentStepIndex = request.approvalLine.indexOf(currentUserEmail);

        if (currentStepIndex !== request.currentApprovalStep) {
            alert('아직 결재 순서가 도착하지 않았습니다.');
            return;
        }

        try {
            const docRef = doc(db, 'drafts', request.id);

            if (currentStepIndex === request.approvalLine.length - 1) {
                await updateDoc(docRef, {
                    status: 'approved',
                    currentApprovalStep: currentStepIndex + 1
                });

                const authorDocRef = doc(db, 'users', request.authorEmail);
                const authorDoc = await getDoc(authorDocRef);
                const authorData = authorDoc.data();
                const notifications = authorData.notifications || {};
                notifications.completedDocuments = (notifications.completedDocuments || 0) + 1;
                notifications.edms = (notifications.edms || 0) + 1;

                await updateDoc(authorDocRef, {
                    notifications
                });

                console.log('Document successfully approved!');

                // '연차' 카테고리일 경우 전체 일정에 휴가 일정 추가
                if (request.category === '연차') {
                    const eventDocRef = doc(db, `events/${authorData.domain}/company`, `companyEvent_${new Date().toISOString()}`);
                    await setDoc(eventDocRef, {
                        title: `${authorData.fullName} 연차`,
                        description: '연차',
                        location: '회사',
                        start: new Date(request.startDate),
                        end: new Date(request.endDate),
                        type: 'company',
                    });
                }
            } else {
                await updateDoc(docRef, {
                    currentApprovalStep: currentStepIndex + 1
                });

                const nextApprover = request.approvalLine[currentStepIndex + 1];
                const nextApproverDocRef = doc(db, 'users', nextApprover);
                const nextApproverDoc = await getDoc(nextApproverDocRef);
                const notifications = nextApproverDoc.data().notifications || {};
                notifications.requestBox = (notifications.requestBox || 0) + 1;
                notifications.edms = (notifications.edms || 0) + 1;

                await updateDoc(nextApproverDocRef, {
                    notifications
                });

                console.log('Approval moved to the next person.');
            }

            // 현재 결재자에서 알림 카운트 감소
            const currentUserDocRef = doc(db, 'users', currentUserEmail);
            const currentUserDoc = await getDoc(currentUserDocRef);
            const currentUserNotifications = currentUserDoc.data().notifications || {};
            currentUserNotifications.requestBox = Math.max((currentUserNotifications.requestBox || 0) - 1, 0);
            currentUserNotifications.edms = Math.max((currentUserNotifications.edms || 0) - 1, 0);

            await updateDoc(currentUserDocRef, {
                notifications: currentUserNotifications
            });

            // 요청 목록에서 제거
            setRequests(prevRequests => prevRequests.filter(req => req.id !== request.id));

        } catch (error) {
            console.error('Error approving the document:', error);
        }
    };

    const handleReject = async (request, reason) => {
        const currentUserEmail = sessionStorage.getItem('email');
        const currentStepIndex = request.approvalLine.indexOf(currentUserEmail);

        if (currentStepIndex !== request.currentApprovalStep) {
            alert('아직 결재 순서가 도착하지 않았습니다.');
            return;
        }

        if (!reason) {
            alert('반려 사유를 입력해야 합니다.');
            return;
        }

        try {
            const docRef = doc(db, 'drafts', request.id);

            await updateDoc(docRef, {
                status: 'rejected',
                rejectionReason: reason,
                currentApprovalStep: currentStepIndex
            });

            const authorDocRef = doc(db, 'users', request.authorEmail);
            const authorDoc = await getDoc(authorDocRef);
            const notifications = authorDoc.data().notifications || {};
            notifications.rejectedDocuments = (notifications.rejectedDocuments || 0) + 1;
            notifications.edms = (notifications.edms || 0) + 1;

            await updateDoc(authorDocRef, {
                notifications
            });

            console.log('Document successfully rejected!');

            // 현재 결재자에서 알림 카운트 감소
            const currentUserDocRef = doc(db, 'users', currentUserEmail);
            const currentUserDoc = await getDoc(currentUserDocRef);
            const currentUserNotifications = currentUserDoc.data().notifications || {};
            currentUserNotifications.requestBox = Math.max((currentUserNotifications.requestBox || 0) - 1, 0);
            currentUserNotifications.edms = Math.max((currentUserNotifications.edms || 0) - 1, 0);

            await updateDoc(currentUserDocRef, {
                notifications: currentUserNotifications
            });

            // 요청 목록에서 제거
            setRequests(prevRequests => prevRequests.filter(req => req.id !== request.id));

        } catch (error) {
            console.error('Error rejecting the document:', error);
        }
    };

    return (
        <div className="approval_requests_page">
            <h2>결재 요청함</h2>
            {requests.length > 0 ? (
                <ul>
                    {requests.map((request) => (
                        <li key={request.id}>
                            <h3>{request.title}</h3>
                            <p>{request.content}</p>
                            <p>기안자: {request.authorName} ({request.authorPosition})</p>
                            <button onClick={() => handleApprove(request)}>승인</button>
                            <button onClick={() => {
                                const reason = prompt('반려 사유를 입력해 주세요.');
                                if (reason) handleReject(request, reason);
                            }}>반려</button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>결재 요청이 없습니다.</p>
            )}
        </div>
    );
};

//결제대기함
export const ApprovalWaiting = () => {
    const [drafts, setDrafts] = useState([]);

    useEffect(() => {
        const fetchDrafts = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) {
                console.error('No user logged in');
                return;
            }

            const userEmail = sessionStorage.getItem('email');
            const draftsRef = collection(db, 'drafts');
            const q = query(draftsRef, where('authorEmail', '==', userEmail), where('currentApprovalStep', '==', 0));

            try {
                const querySnapshot = await getDocs(q);
                const fetchedDrafts = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setDrafts(fetchedDrafts);
            } catch (error) {
                console.error('Error fetching drafts:', error);
            }
        };

        fetchDrafts();
    }, []);

    return (
        <div className="waiting-for-approval">
            <h2>결재대기함</h2>
            {drafts.length > 0 ? (
                <ul>
                    {drafts.map((draft) => (
                        <li key={draft.id}>
                            <h3>{draft.title}</h3>
                            <p>{draft.content}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>결재를 기다리는 문서가 없습니다.</p>
            )}
        </div>
    );
};



// 결재진행함
export const ApprovalInProgress = () => {
    const [drafts, setDrafts] = useState([]);

    useEffect(() => {
        const fetchDrafts = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) {
                console.error('No user logged in');
                return;
            }

            const userEmail = sessionStorage.getItem('email');
            const draftsRef = collection(db, 'drafts');
            const q = query(
                draftsRef,
                where('authorEmail', '==', userEmail),
                where('currentApprovalStep', '>', 0),
                where('status', '==', 'pending')
            );
            try {
                const querySnapshot = await getDocs(q);
                const fetchedDrafts = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setDrafts(fetchedDrafts);

            } catch (error) {
                console.error('Error fetching drafts:', error);
            }
        };

        fetchDrafts();
    }, []);

    const handleDraftClick = async (draftId) => {
        try {
            const userDocRef = doc(db, 'users', sessionStorage.getItem('email'));
            const userDoc = await getDoc(userDocRef);
            const notifications = userDoc.data().notifications || {};
            notifications.approvalInProgress = Math.max((notifications.approvalInProgress || 0) - 1, 0);
            notifications.edms = Math.max((notifications.edms || 0) - 1, 0);

            await updateDoc(userDocRef, {
                notifications
            });

            // 여기서 추가로 해당 문서의 상세 정보를 불러오는 로직을 추가할 수 있습니다.
            console.log(`Draft ${draftId} clicked`);
        } catch (error) {
            console.error('Error updating notification count:', error);
        }
    };

    return (
        <div className="approval-in-progress">
            <h2>결재진행함</h2>
            {drafts.length > 0 ? (
                <ul>
                    {drafts.map((draft) => (
                        <li key={draft.id} onClick={() => handleDraftClick(draft.id)}>
                            <h3>{draft.title}</h3>
                            <p>{draft.content}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>결재가 진행중인 문서가 없습니다.</p>
            )}
        </div>
    );
};

// 완료문서함
export const ApprovalComplete = () => {
    const [drafts, setDrafts] = useState([]);

    useEffect(() => {
        const fetchDrafts = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) {
                console.error('No user logged in');
                return;
            }

            const userEmail = sessionStorage.getItem('email');
            const draftsRef = collection(db, 'drafts');
            const q = query(draftsRef, where('authorEmail', '==', userEmail), where('status', '==', 'approved'));

            try {
                const querySnapshot = await getDocs(q);
                const fetchedDrafts = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setDrafts(fetchedDrafts);

            } catch (error) {
                console.error('Error fetching drafts:', error);
            }
        };

        fetchDrafts();
    }, []);

    const handleDraftClick = async (draftId) => {
        try {
            const userDocRef = doc(db, 'users', sessionStorage.getItem('email'));
            const userDoc = await getDoc(userDocRef);
            const notifications = userDoc.data().notifications || {};
            notifications.completedDocuments = Math.max((notifications.completedDocuments || 0) - 1, 0);
            notifications.edms = Math.max((notifications.edms || 0) - 1, 0);

            await updateDoc(userDocRef, {
                notifications
            });

            // 여기서 추가로 해당 문서의 상세 정보를 불러오는 로직을 추가할 수 있습니다.
            console.log(`Draft ${draftId} clicked`);
        } catch (error) {
            console.error('Error updating notification count:', error);
        }
    };

    return (
        <div className="complete-approval">
            <h2>완료문서함</h2>
            {drafts.length > 0 ? (
                <ul>
                    {drafts.map((draft) => (
                        <li key={draft.id} onClick={() => handleDraftClick(draft.id)}>
                            <h3>{draft.title}</h3>
                            <p>{draft.content}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>결재가 완료된 문서가 없습니다.</p>
            )}
        </div>
    );
};

// 반려문서함
export const ApprovalRejected = () => {
    const [drafts, setDrafts] = useState([]);

    useEffect(() => {
        const fetchDrafts = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) {
                console.error('No user logged in');
                return;
            }

            const userEmail = sessionStorage.getItem('email');
            const draftsRef = collection(db, 'drafts');
            const q = query(draftsRef, where('authorEmail', '==', userEmail), where('status', '==', 'rejected'));

            try {
                const querySnapshot = await getDocs(q);
                const fetchedDrafts = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setDrafts(fetchedDrafts);

            } catch (error) {
                console.error('Error fetching drafts:', error);
            }
        };

        fetchDrafts();
    }, []);

    const handleDraftClick = async (draftId) => {
        try {
            const userDocRef = doc(db, 'users', sessionStorage.getItem('email'));
            const userDoc = await getDoc(userDocRef);
            const notifications = userDoc.data().notifications || {};
            notifications.rejectedDocuments = Math.max((notifications.rejectedDocuments || 0) - 1, 0);
            notifications.edms = Math.max((notifications.edms || 0) - 1, 0);

            await updateDoc(userDocRef, {
                notifications
            });

            // 여기서 추가로 해당 문서의 상세 정보를 불러오는 로직을 추가할 수 있습니다.
            console.log(`Draft ${draftId} clicked`);
        } catch (error) {
            console.error('Error updating notification count:', error);
        }
    };

    return (
        <div className="rejected-approval">
            <h2>반려문서함</h2>
            {drafts.length > 0 ? (
                <ul>
                    {drafts.map((draft) => (
                        <li key={draft.id} onClick={() => handleDraftClick(draft.id)}>
                            <h3>{draft.title}</h3>
                            <p>{draft.content}</p>
                            <p>반려사유 : {draft.rejectionReason}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>결재가 반려된 문서가 없습니다.</p>
            )}
        </div>
    );
};