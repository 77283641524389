import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, deleteDoc, updateDoc, collection, addDoc, onSnapshot } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db,storage } from '../../firebase';
import { getAuth, signInWithPopup, GithubAuthProvider } from 'firebase/auth';
import hljs from 'highlight.js';  // Highlight.js 추가
import 'highlight.js/styles/atom-one-dark.css';
import '../css/LHPostDetail.css';

export const PostDetail = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null);
    const [formattedContent, setFormattedContent] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editedTitle, setEditedTitle] = useState('');
    const [editedContent, setEditedContent] = useState('');
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const auth = getAuth();

    const formatDate = (timestamp) => {
        const date = timestamp.toDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}.${month}.${day} ${hours}:${minutes}`;
    };

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const docRef = doc(db, "posts", id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const postData = docSnap.data();
                    setPost(postData);
                    setEditedTitle(postData.title);
                    setEditedContent(postData.content);

                    // `.ql-syntax` -> `<pre><code class="hljs">` 형식으로 변환
                    const transformedContent = postData.content.replace(
                        /<pre class="ql-syntax"/g,
                        '<pre><code class="hljs"'
                    );
                    setFormattedContent(transformedContent);
                } else {
                    alert("게시물을 찾을 수 없습니다.");
                }
            } catch (error) {
                console.error("Error fetching document:", error);
            }
        };

        const fetchComments = () => {
            const commentsRef = collection(db, "posts", id, "comments");
            onSnapshot(commentsRef, (snapshot) => {
                const commentsList = snapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());
                setComments(commentsList);
            });
        };

        fetchPost();
        fetchComments();
    }, [id]);

    // Highlight.js로 코드 블록 하이라이팅 적용
    useEffect(() => {
        if (formattedContent) {
            // highlightAll()으로 전체 코드 블록에 하이라이팅 적용
            document.querySelectorAll('pre code').forEach((block) => {
                hljs.highlightBlock(block); // highlightBlock으로 개별 코드 블록 하이라이팅
            });
        }
    }, [formattedContent]);

    const handleGitHubLogin = async () => {
        const provider = new GithubAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const githubAccessToken = result._tokenResponse.oauthAccessToken;
            const response = await fetch('https://api.github.com/user', {
                headers: {
                    Authorization: `token ${githubAccessToken}`,
                },
            });
            const githubData = await response.json();

            setUser({
                ...user,
                displayName: githubData.login,
                photoURL: githubData.avatar_url,
            });
        } catch (error) {
            console.error("GitHub 로그인 오류:", error);
        }
    };

    const handleDelete = async () => {
        const enteredPassword = prompt("게시물을 삭제하려면 비밀번호를 입력하세요:");
        if (!enteredPassword) return;

        try {
            const docRef = doc(db, "settings", "password");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const firebasePassword = docSnap.data().password;
                if (enteredPassword === firebasePassword) {
                    if (post.fileUrl) {
                        const fileRef = ref(storage, `files/${post.fileName}`);
                        await deleteObject(fileRef);
                    }
                    await deleteDoc(doc(db, "posts", id));
                    alert("게시물이 삭제되었습니다.");
                    navigate('/learninghub');
                } else {
                    alert("비밀번호가 틀렸습니다.");
                }
            } else {
                alert("비밀번호 정보를 가져올 수 없습니다.");
            }
        } catch (error) {
            console.error("Error", error);
            alert("게시물 삭제 중 오류가 발생했습니다.");
        }
    };

    const handleEdit = async () => {
        const enteredPassword = prompt("게시물을 수정하려면 비밀번호를 입력하세요:");
        if (!enteredPassword) return;

        try {
            const docRef = doc(db, "settings", "password");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const firebasePassword = docSnap.data().password;
                if (enteredPassword === firebasePassword) {
                    setIsEditing(true);
                } else {
                    alert("비밀번호가 틀렸습니다.");
                }
            } else {
                alert("비밀번호 정보를 가져올 수 없습니다.");
            }
        } catch (error) {
            console.error("Error", error);
            alert("게시물 수정 중 오류가 발생했습니다.");
        }
    };

    const handleSaveEdit = async () => {
        try {
            const postRef = doc(db, "posts", id);
            await updateDoc(postRef, {
                title: editedTitle,
                content: editedContent,
            });
            alert("게시물이 수정되었습니다.");
            setIsEditing(false);
            setPost(prev => ({ ...prev, title: editedTitle, content: editedContent }));
        } catch (error) {
            console.error("Error updating document:", error);
            alert("게시물 수정 중 오류가 발생했습니다.");
        }
    };

    const handleAddComment = async () => {
        if (!newComment.trim()) return;
        const commentsRef = collection(db, "posts", id, "comments");

        try {
            await addDoc(commentsRef, {
                text: newComment,
                authorName: user.displayName || user.email || "Anonymous",
                userId: user.uid,
                photoURL: user.photoURL,
                timestamp: new Date(),
            });
            setNewComment('');
        } catch (error) {
            console.error("댓글 추가 오류:", error);
        }
    };

    const handleDeleteComment = async (commentId, commentUserId) => {
        if (user.uid !== commentUserId) {
            alert("본인이 작성한 댓글만 삭제할 수 있습니다.");
            return;
        }
        const confirmDelete = window.confirm("댓글을 삭제하시겠습니까?");
        if (!confirmDelete) {
            return; 
        }
        
        const commentRef = doc(db, "posts", id, "comments", commentId);
        try {
            await deleteDoc(commentRef);
            alert("댓글이 삭제되었습니다.");
        } catch (error) {
            console.error("댓글 삭제 오류:", error);
            alert("댓글 삭제 중 오류가 발생했습니다.");
        }
    };
    
    if (!post) return <div className='LHDetail_Container'>잠시만 기다려주세요...<span className='Loading'></span></div>;

    return (
        <div className='LHDetail_Container'>
            <h1 className='Title' onClick={() => navigate('/learninghub')}>Learning Hub</h1>
            <div className='TitleWrap'>
                {isEditing ? (
                    <input
                        type="text"
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                    />
                ) : (
                    <h2 className='Title2'>{post.title}</h2>
                )}
                <div>{post.date?.toDate().toLocaleDateString()}</div>
            </div>

            {isEditing ? (
                <textarea
                    value={editedContent}
                    onChange={(e) => setEditedContent(e.target.value)}
                />
            ) : (
                <div dangerouslySetInnerHTML={{ __html: formattedContent }} className='post' />
            )}

            {post.fileUrl && post.fileName && (
                <div className="FileDownload">
                    첨부파일 : <a href={post.fileUrl} download={post.fileName} target="_blank" rel="noopener noreferrer">
                        {post.fileName}
                    </a>
                </div>
            )}

            <div className='Tags'>Tags : {post.tags?.join(', ')}</div>

            {isEditing ? (
                <button onClick={handleSaveEdit} className="save-btn">저장</button>
            ) : (
                <div className='flx'>
                    <button onClick={handleEdit} className="edit-btn">수정</button>
                    <button onClick={handleDelete} className="del-btn">삭제</button>
                </div>
            )}

            <div className="comment-section">
                <h3 className='comment-title'>댓글</h3>
                {comments.map(comment => (
                    <ul key={comment.id} className="commentwrap">
                        <li className="comments">
                            {comment.photoURL && (
                                <div>
                                    <img src={comment.photoURL} alt="프로필 사진" className="profile-pic" />
                                    <strong>{comment.authorName}</strong>
                                </div>
                            )}
                            <div className='comment-txt'>{comment.text}</div>
                        </li>
                        <span className="comment-date">
                            {comment.timestamp && formatDate(comment.timestamp)}
                        </span>
                        {user && user.uid === comment.userId && (
                            <button
                                onClick={() => handleDeleteComment(comment.id, comment.userId)}
                                className="comment-del-btn"
                            ><span className="material-symbols-outlined">close</span></button>
                        )}
                    </ul>
                ))}
            </div>

            {user ? (
                <div className="comment-input">
                    <textarea
                        className='comment'
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder="댓글을 작성하세요"
                        maxLength={50}
                    />
                    <button className='' onClick={handleAddComment}>작성</button>
                </div>
            ) : (
                <div className="comment-input">
                    <textarea
                        className='comment'
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder="댓글을 작성하려면 로그인하세요"
                        readOnly
                    />
                    <button onClick={handleGitHubLogin}><svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>GitHub</title><path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"></path></svg></button>
                </div>
            )}
        </div>
    );
};
