import React, { useState, useEffect } from 'react';
import { app } from '../../firebase'; // Import the Firebase app instance
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import '../css/UserInfo.css';

export const UserInfo = () => {
  const [reservationsInfo, setReservationsInfo] = useState([]);
  const auth = getAuth(app);
  const db = getFirestore(app);
    const [showDetails, setShowDetails] = useState({});

  useEffect(() => {
    const fetchReservations = async (userId) => {
      const reservationsRef = collection(db, 'reservations');
      const q = query(reservationsRef, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);

      const reservationsPromises = querySnapshot.docs.map(async (docSnapshot) => {
        const reservationData = docSnapshot.data();
        const performanceRef = doc(db, 'performances', reservationData.performanceId);
        const performanceSnapshot = await getDoc(performanceRef);

        if (performanceSnapshot.exists()) {
          const performanceData = performanceSnapshot.data();
          return {
            performanceName: performanceData.description, // 공연 이름
            performanceTime: performanceData.schedules[0].date, // 공연 시간
            seat: reservationData.seats.join(", ") // 예약 좌석
          };
        }
        return null;
      });

      const fetchedReservations = (await Promise.all(reservationsPromises)).filter(Boolean);
      setReservationsInfo(fetchedReservations);
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchReservations(user.uid);
      } else {
        setReservationsInfo([]); // 로그아웃시 예약 정보 초기화
      }
    });

    return () => unsubscribe(); // 구독 해제
  }, [auth, db]);


  const toggleDetails = (index) => {
    setShowDetails(prev => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div className='noScroll'>
      <div className='UserInfoWrap'>
      <div className='UserInfo'>
        <h3 className='Title'>예약 내역</h3>
        <ul>
          {reservationsInfo.map((reservation, index) => (
            <li key={index}>
              <button className='Perform-Title' onClick={() => toggleDetails(index)}>
                공연 이름 : {reservation.performanceName}
              </button>
              <div className={`detail-container ${showDetails[index] ? 'show' : ''}`}>
                <div>
                  시간 : {reservation.performanceTime.replace('T', ' ')} <br />
                  좌석 : {reservation.seat}
                  <div className="barcode" />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
        </div>
    </div>
  );
};
