import { HeaderMenuERP } from "../../components/HeaderMenu_ERP";

export const Quick_Parcel = () => {

    return (
      <div className="ERP-Wrap">
      <HeaderMenuERP/>
퀵/택배서비스
      </div>
    );
  };
  