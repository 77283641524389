import { initializeApp, getApps } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { useEffect, useState } from "react";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB8HGEEpm3dOV3ngaxkD0LULKoq5q8dwO4",
  authDomain: "pofol001-5ed52.firebaseapp.com",
  projectId: "pofol001-5ed52",
  storageBucket: "pofol001-5ed52.appspot.com",
  messagingSenderId: "705217139095",
  appId: "1:705217139095:web:b780f576516b5b30bd34d2",
  measurementId: "G-V80K8RLFWB"
};

// Initialize Firebase
// const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

// const db = getFirestore(app);
// export { db, app };



const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage= getStorage(app)

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // user 객체가 있으면 true, 없으면 false로 설정
    });

    return () => unsubscribe(); // 컴포넌트 언마운트 시 구독 취소
  }, []);

  return isAuthenticated;
};
export { app };

export const Weather_API_KEY = "163c2c5f76b523d8d2147930060e9bb9";