import React from 'react';
import '../css/Pokemon.css';

    const typeTranslations = {
        "normal": "노말",
        "fighting": "격투",
        "flying": "비행",
        "poison": "독",
        "ground": "땅",
        "rock": "바위",
        "bug": "벌레",
        "ghost": "고스트",
        "steel": "강철",
        "fire": "불",
        "water": "물",
        "grass": "풀",
        "electric": "전기",
        "psychic": "에스퍼",
        "ice": "얼음",
        "dragon": "드래곤",
        "dark": "악",
        "fairy": "페어리"
    };


// 포켓몬 타입을 한글로 변환
export const getKoreanType = (englishType) => {
    return typeTranslations[englishType] || englishType;
};


// 포켓몬 타입을 출력
export const PokemonType = ({ type }) => {
    return <span className={`Poke_Type ${type}`}>{getKoreanType(type)}</span>;
};