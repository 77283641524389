import "../css/Footer.css";
import { useState } from "react";
import { useLocation } from 'react-router-dom';
const Footer = ({ selectedMode }) => {

  const instagramLink = "https://www.instagram.com/seon.i.k";
  const kakaoLink = "https://open.kakao.com/o/sBLYhhne";
  const location = useLocation();


  // 인스타그램 링크를 열기 위한 함수
  const openInstagramLink = () => {
    window.open(instagramLink, "_blank");
  };

  // 카카오톡 링크를 열기 위한 함수
  const openKakaoLink = () => {
    window.open(kakaoLink, "_blank");
  };

  if (location.pathname === '/erp') {
  }


  return (
    <>


      <div className={`Footer ${selectedMode === 'lightmode' ? 'lightmode' : 'darkmode'}`}>
        <div className="logoWrap">
          <div className="logo"></div>
          <div className="flx">
            <button className="insta" onClick={openInstagramLink}></button>
            <button className="kakao" onClick={openKakaoLink}></button>
          </div>
        </div>
        <div className="contactWrap">
          <div className="title">CONTACT</div>
          <div className="subs">tjsdlrdl122@naver.com</div>
          <div className="subs">010-2225-0394</div>
        </div>
        <div className="etcWrap">
          <div className="title">Coptrights</div>
          <div className="subs">ⓒ2024 김선익 All rights reserved</div>
          <div className="subs">이 페이지는 포트폴리오입니다.</div>
        </div>

      </div>
    </>

  );
}

export default Footer;
