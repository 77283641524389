import { useState, useRef, useEffect } from 'react';
import { HeaderMenuERP } from '../../components/HeaderMenu_ERP';

export const Ladder = () => {

return(
  <div className="ERP-Wrap">
  <HeaderMenuERP/>
사다리 추후 변경 될수도 ..
  </div>
)
}
