import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/ERP.css';
import { HeaderMenuERP } from '../../components/HeaderMenu_ERP';
import { db, auth } from '../../../firebase';
import { getAuth, signOut, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, onSnapshot, getDoc, updateDoc, arrayUnion, collection, query, where, getDocs, setDoc, serverTimestamp, arrayRemove } from 'firebase/firestore';
import CalendarComponent from './ERPCalendar';
import EventModal from './EventAdd';

export const ERP = () => {
  const [fullName, setFullName] = useState('');
  const [position, setPosition] = useState('');
  const [department, setDepartment] = useState('');
  const [noticeContent, setNoticeContent] = useState('');
  const [isEditingNotice, setIsEditingNotice] = useState(false);
  const [newNoticeContent, setNewNoticeContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [frequentContacts, setFrequentContacts] = useState([]);
  const [showDropdownFor, setShowDropdownFor] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const [messageCounts, setMessageCounts] = useState({
    edms: 0,
    schedule: 0,
    board: 0,
    documentbox: 0,
    human_resources_management: 0,
    accounting_management: 0,
    messenger: 0,
    contacts: 0,
    notes: 0,
    deals: 0,
  });

  const navigate = useNavigate();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Now we can sign in
      })
      .catch((error) => {
        console.error("Error setting persistence:", error);
      });

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        fetchNotice(user);
        fetchFrequentContacts(user);
        fetchMessageCounts(user);
        fetchContacts(user);
      } else {
        navigate('/projects/erp/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchNotice = async (user) => {
    const userEmail = user.email;
    const userDomain = userEmail.substring(userEmail.lastIndexOf("@") + 1);
    const noticeDocRef = doc(db, userDomain, "Notice");

    setFullName(sessionStorage.getItem('username'));
    setPosition(sessionStorage.getItem('position'));
    setDepartment(sessionStorage.getItem('department'));
    try {
      const docSnap = await getDoc(noticeDocRef);

      if (docSnap.exists()) {
        setNoticeContent(docSnap.data().content);
      } else {
        console.log("No such document!");
        setNoticeContent("");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };

  const fetchFrequentContacts = async (user) => {
    const userDocRef = doc(db, 'users', user.email);
    try {
      const docSnapshot = await getDoc(userDocRef);
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        setFrequentContacts(userData.frequentContacts || []);
      }
    } catch (error) {
      console.error("Error fetching frequent contacts:", error);
    }
  };

  const fetchMessageCounts = (user) => {
    const userDocRef = doc(db, 'users', user.email);
    const unsubscribe = onSnapshot(userDocRef, (doc) => {
      if (doc.exists()) {
        setMessageCounts(doc.data().notifications);
      }
    });

    return unsubscribe;
  };

  const fetchContacts = async (user) => {
    const currentUserDomain = user.email.split('@')[1];
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('domain', '==', currentUserDomain));

    const querySnapshot = await getDocs(q);
    const usersData = [];
    querySnapshot.forEach((doc) => {
      if (doc.id !== user.email) {
        usersData.push(doc.data());
      }
    });

    setContactList(usersData);
  };

  const handleAddFrequentContact = async (email) => {
    if (frequentContacts.includes(email)) {
      alert('이미 즐겨찾기에 등록되었습니다.');
      return;
    }
    const userDocRef = doc(db, 'users', currentUser.email);

    try {
      await updateDoc(userDocRef, {
        frequentContacts: arrayUnion(email)
      });
      setFrequentContacts((prevContacts) => [...prevContacts, email]);
      alert('즐겨찾기에 추가되었습니다.');
    } catch (error) {
      console.error('즐겨찾기를 추가하는데 실패했습니다:', error);
    }
  };

  const renderFrequentContactsList = () => {
    return frequentContacts.map((email, index) => (
      <div key={index} className={`frequent-contact ${showDropdownFor === email ? 'selected' : ''}`} onClick={() => handleShowDropdown(email)}>
        <span>{email}</span>
        {showDropdownFor === email && (
          <div className="action-buttons">
            <button onClick={() => handleAction1(email)}>메신저</button>
            <button onClick={() => handleAction2(email)}>쪽지</button>
            <button onClick={() => handleDeleteContact(email)}>삭제</button>
          </div>
        )}
      </div>
    ));
  };

  const renderContactList = () => {
    return contactList.map((user, index) => (
      <option key={index} value={user.email}>
        {user.fullName}
      </option>
    ));
  };

  const handleAction1 = (email) => {
    const currentUserDomain = currentUser.email.split('@')[1];
    navigate(`/projects/erp/messenger`, { state: { user: `${email}@${currentUserDomain}` } });
  };

  const handleAction2 = (email) => {
    navigate(`/projects/erp/notes`, { state: { user: email } });
  };

  const handleEditNotice = () => {
    setIsEditingNotice(true);
    setNewNoticeContent(noticeContent);
  };

  const handleSaveNotice = async () => {
    const userDomain = currentUser.email.split('@')[1];
    try {
      await setDoc(doc(db, userDomain, 'Notice'), {
        content: newNoticeContent
      });
      setNoticeContent(newNoticeContent);
      setIsEditingNotice(false);
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  const handleModuleClick = type => {
    navigate(`/projects/erp/${type}`);
  };

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/projects/erp/login');
      sessionStorage.clear();
    } catch (error) {
      console.error('로그아웃 에러', error);
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const recordAttendance = async (type) => {
    const user = currentUser;
    if (!user) {
      console.error('사용자 정보가 없습니다.');
      return;
    }

    const today = formatDate(new Date());
    const attendanceDocRef = doc(db, `attendance/`, user.uid + '_' + today);

    try {
      const docSnap = await getDoc(attendanceDocRef);
      if (docSnap.exists() && docSnap.data()[type]) {
        alert(`${type === 'checkIn' ? '출근' : '퇴근'} 기록이 이미 존재합니다.`);
        return;
      }

      const updateData = { [type]: serverTimestamp(), name: user.displayName || user.email, date: today };
      await setDoc(attendanceDocRef, updateData, { merge: true });
      alert(`${type === 'checkIn' ? '출근' : '퇴근'} 기록을 성공적으로 저장했습니다.`);
    } catch (error) {
      console.error('출퇴근 기록 중 오류가 발생했습니다:', error);
    }
  };

  const handleShowDropdown = (email) => {
    setShowDropdownFor(showDropdownFor === email ? null : email);
  };

  const handleDeleteContact = async (email) => {
    const userDocRef = doc(db, 'users', currentUser.email);

    try {
      await updateDoc(userDocRef, {
        frequentContacts: arrayRemove(email)
      });

      setFrequentContacts(frequentContacts.filter(contactEmail => contactEmail !== email));
      setShowDropdownFor(null);
      alert(`${email}이(가) 자주 연락하는 목록에서 삭제되었습니다.`);
    } catch (error) {
      console.error('연락처를 삭제하는 중 오류가 발생했습니다:', error);
    }
  };

  return (
    <div className="ERP-Wrap">
      <HeaderMenuERP />
      <div className="Main-Content">
        <div className='L_SideBar'>
          <div className="Item-Wrap">
            <div className='UserInfo'>
              <span className='UserName'>{department}팀<br/> {fullName} {position}님 
              <button className='more' onClick={handleToggleMenu}>☰</button>
              </span>
             
              {menuOpen && (
                <div className="usermenu">
                  <button onClick={() => recordAttendance('checkIn')}>출근</button>
                  <button onClick={() => recordAttendance('checkOut')}>퇴근</button>
                  <button onClick={handleLogout}>로그아웃</button>
                </div>
              )}
         
                <CalendarComponent />
            </div>
          </div>
         
          <div className="Item-Wrap">
      
                <h2 className='Title'>즐겨찾기</h2>
                <div className="frequent-contacts-list">
                  {renderFrequentContactsList()}
                </div>
       
                <h2 className='Title'>연락처 추가하기</h2>
                <select onChange={(e) => handleAddFrequentContact(e.target.value)}>
                  <option value="">--</option>
                  {renderContactList()}
                </select>

          </div>
            <div className='Schedule'>
              <button className='Schedule_Add' onClick={toggleModal}>📅 오늘의 일정을 등록해보세요.</button>
              {isModalOpen && <EventModal onClose={toggleModal} />}
            </div>
        </div>
        <div className='Module_Wrap'>
          <div className="Notices">
            <h2 className="Title">NOTICE 📢</h2>
            {!isEditingNotice ? (
              <>
                <div className="notice-content">{noticeContent}</div>
                <button onClick={handleEditNotice}>수정</button>
              </>
            ) : (
              <>
                <input type='text' value={newNoticeContent} onChange={(e) => setNewNoticeContent(e.target.value)} />
                <button onClick={handleSaveNotice}>저장</button>
              </>
            )}
          </div>
          <div className="Modules">
            <div className='module' onClick={() => handleModuleClick('edms')} >전자결재
                  <span className="material-symbols-outlined">
                    approval
                  </span>
              {messageCounts.edms > 0 && (
                <div>
                  <span className='alarm'>{messageCounts.edms}</span>
                </div>
              )}
            </div>
            <div className='module' onClick={() => handleModuleClick('schedule')} >일정
                  <span className="material-symbols-outlined">
                    calendar_clock
                  </span>
              {messageCounts.schedule > 0 && (
                <div>
                  <span className='alarm'>{messageCounts.schedule}</span>
                </div>
              )}
            </div>
            <div className='module' onClick={() => handleModuleClick('board')} >게시판
                  <span className="material-symbols-outlined">
                    content_paste
                  </span>
              {messageCounts.board > 0 && (
                <div>
                  <span className='alarm'>{messageCounts.board}</span>
                </div>
              )}
            </div>
            <div className='module' onClick={() => handleModuleClick('documentbox')} >업무문서함
                  <span className="material-symbols-outlined">
                    folder_open
                  </span>
              {messageCounts.documentbox > 0 && (
                <div>
                  <span className='alarm'>{messageCounts.documentbox}</span>
                </div>
              )}
            </div>
            <div className='module' onClick={() => handleModuleClick('human_resources_management')} >인사관리
                  <span className="material-symbols-outlined">
                    groups
                  </span>
              {messageCounts.human_resources_management > 0 && (
                <div>
                  <span className='alarm'>{messageCounts.human_resources_management}</span>
                </div>
              )}
            </div>
            <div className='module' onClick={() => handleModuleClick('accounting_management')} >회계관리
                  <span className="material-symbols-outlined">
                    payments
                  </span>
              {messageCounts.accounting_management > 0 && (
                <div>
                  <span className='alarm'>{messageCounts.accounting_management}</span>
                </div>
              )}
            </div>
            <div className='module' onClick={() => handleModuleClick('messenger')} >메신저
                  <span className="material-symbols-outlined">
                    forum
                  </span>
              {messageCounts.messenger > 0 && (
                <div>
                  <span className='alarm'>{messageCounts.messenger}</span>
                </div>
              )}
            </div>
            <div className='module' onClick={() => handleModuleClick('contacts')} >사내 연락망
                  <span className="material-symbols-outlined">
                    contacts
                  </span>
              {messageCounts.contacts > 0 && (
                <div>
                  <span className='alarm'>{messageCounts.contacts}</span>
                </div>
              )}
            </div>
            <div className='module' onClick={() => handleModuleClick('notes')} >쪽지
                  <span className="material-symbols-outlined">
                    send
                  </span>
              {messageCounts.notes > 0 && (
                <div>
                  <span className='alarm'>{messageCounts.notes}</span>
                </div>
              )}
            </div>
            <div className='module' onClick={() => handleModuleClick('deals')} >거래처
                  <span className="material-symbols-outlined">
                    work
                  </span>
              {messageCounts.deals > 0 && (
                <div>
                  <span className='alarm'>{messageCounts.deals}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
      </div>
    </div>
  );
};
