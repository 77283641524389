import React, { useState, useEffect, useRef, useCallback } from 'react';

import '../css/Projects.css';
import { Link } from 'react-router-dom';

export const Projects = () => {


    return (
        <div className='noScroll'>
            <div className='Projects_Container'>
                <h1 className='Title'>프로젝트</h1>
                <div class="project-card">
                    <h3>웹 게임</h3>
                    <p>캔버스를 활용한 간단한 웹 게임 프로젝트</p>
                    <div class="tech-stack">
                    </div>
                    <Link to="/projects/webgame">View More</Link>
         
                </div>
                <div class="project-card">
                    <h3>날씨정보</h3>
                    <p>openweather API, react-leaflet를 활용한<br/> 날씨정보 프로젝트</p>
                    <div class="tech-stack">
                    </div>
                    <Link to="/projects/weather">View More</Link>
           
                </div>
                <div class="project-card">
                    <h3>포켓몬 도감</h3>
                    <p>PokeAPI를 활용한 포켓몬 도감 프로젝트</p>
                    <div class="tech-stack">
                    </div>
                    <Link to="/projects/pokemon">View More</Link>
                   
                </div>
                <div class="project-card">
                    <h3>ERP</h3>
                    <p>Firebase를 활용한 ERP 프로젝트</p>
                    <div class="tech-stack">
                    </div>
                    <a href="/projects/erp" target="_blank" rel="noopener noreferrer">View More</a>
                </div>

            </div>
        </div>
    );
};
