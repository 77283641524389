import { HeaderMenuERP } from "../../components/HeaderMenu_ERP";

export const Legal_Education = () => {

    return (
      <div className="ERP-Wrap">
      <HeaderMenuERP/>
법정의무교육
      </div>
    );
  };
  