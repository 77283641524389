import { HeaderMenuERP } from "../../components/HeaderMenu_ERP";

export const Welfare_Mall = () => {

    return (
      <div className="ERP-Wrap">
      <HeaderMenuERP/>
복지몰
      </div>
    );
  };
  