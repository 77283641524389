import React, { useState } from 'react';
import '../css/SeatSelectionModal.css'
function SeatSelectionModal({ schedule, people, onSeatSelect, onClose }) {
  const [selectedSeats, setSelectedSeats] = useState([]);

  // 좌석을 열 별로 그룹화
  const groupedSeats = schedule.seats.reduce((acc, seat) => {
    const row = seat.number.charAt(0);
    if (!acc[row]) acc[row] = [];
    acc[row].push(seat);
    return acc;
  }, {});



  // 좌석 버튼의 클래스 결정
  const getSeatClassName = (seat) => {
    if (selectedSeats.includes(seat.number)) return 'seat selected';
    if (seat.status === 'reserved') return 'seat reserved';
    return 'seat';
  };





  const findContinuousSeats = (seatIndex) => {
    let continuousSeats = [];
    for (let i = seatIndex; i < seatIndex + people; i++) {
      if (!schedule.seats[i] || schedule.seats[i].status !== 'available' || schedule.seats[i].number.charAt(0) !== schedule.seats[seatIndex].number.charAt(0)) {
        // 조건을 만족하지 못할 경우 경고 메시지 표시
        alert("연속된 좌석 중에 예약 불가능한 좌석이 포함되어 있습니다.");
        return [];
      }
      continuousSeats.push(schedule.seats[i].number);
    }
    return continuousSeats;
  };

  // 좌석 클릭 핸들러
  const handleSeatClick = (seatIndex) => {
    const seatNumber = schedule.seats[seatIndex].number;

    // 이미 선택된 좌석을 클릭한 경우
    if (selectedSeats.includes(seatNumber)) {
      setSelectedSeats(selectedSeats.filter(s => s !== seatNumber));
      return;
    }

    // 새로운 좌석을 선택한 경우
    const continuousSeats = findContinuousSeats(seatIndex);
    if (continuousSeats.length === people) {
      setSelectedSeats(continuousSeats);
    }
  };
  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };


  return (
    <div className="seat-selection-modal" onClick={handleModalContentClick}>
      <div className='stage'>무대</div>
<div className='seatwrap'>
  
        {Object.keys(groupedSeats).map(row => (
          <div key={row} className="seat-row">
            {groupedSeats[row].map((seat, index) => (
              <button
                key={seat.number}
                className={getSeatClassName(seat)}
                onClick={() => handleSeatClick(schedule.seats.indexOf(seat))}
                disabled={seat.status === 'reserved'}
              >
                {seat.number}
              </button>
            ))}
          </div>
        ))}
  
</div>
      <div className='btnwrap'>
        <button className='btn1' onClick={() => onSeatSelect(selectedSeats)}>좌석 선택 완료</button>
        <button className='btn1' onClick={onClose}>닫기</button>
      </div>

    </div>
  );
}

export default SeatSelectionModal;