// import { useState, useEffect } from 'react';
// import '../css/Weather.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { 
//     faSun, 
//     faCloudSun, 
//     faCloud, 
//     faCloudShowersHeavy, 
//     faBolt, 
//     faSnowflake, 
//     faSmog, 
//     faMoon,
//     faCloudMoon,
//     faCloudMeatball,
//     faCloudSunRain,
//     faCloudMoonRain
//   } from '@fortawesome/free-solid-svg-icons';
// import { Weather_API_KEY, Weather_Weather_API_KEY } from '../../firebase';

//   const mapWeatherToIconAndClass = (iconCode) => {
//     switch (iconCode) {
//         case '01d': return { icon: faSun, className: 'icon-clear-sky-day' };
//         case '01n': return { icon: faMoon, className: 'icon-clear-sky-night' };

//         case '02d': return { icon: faCloudSun, className: 'icon-few-clouds-day' };
//         case '02n': return { icon: faCloudMoon, className: 'icon-few-clouds-night' };

//         case '03d':
//         case '03n': return { icon: faCloud, className: 'icon-scattered-clouds' };

//         case '04d':
//         case '04n': return { icon: faCloudMeatball, className: 'icon-broken-clouds' };

//         case '09d':
//         case '09n': return { icon: faCloudShowersHeavy, className: 'icon-shower-rain' };

//         case '10d': return { icon: faCloudSunRain, className: 'icon-rain-day' };
//         case '10n': return { icon: faCloudMoonRain, className: 'icon-rain-night' };

//         case '11d':
//         case '11n': return { icon: faBolt, className: 'icon-thunderstorm' };

//         case '13d':
//         case '13n': return { icon: faSnowflake, className: 'icon-snow' };

//         case '50d':
//         case '50n': return { icon: faSmog, className: 'icon-mist' };

//         default: return { icon: faSun, className: 'icon-clear-sky-day' };
//     }
// };


// export const Weather = () => {
//     // const Weather_API_KEY = "163c2c5f76b523d8d2147930060e9bb9";
//     const [forecast, setForecast] = useState([]);
//     const [city, setCity] = useState('Seoul');
//     const [showModal, setShowModal] = useState("");
//     const [selectedWeather, setSelectedWeather] = useState("");


//     useEffect(() => {
//         const fetchWeatherData = async (latitude, longitude) => {
//             try {
//                 const response = await fetch(`https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${Weather_API_KEY}&units=metric&lang=kr`);
//                 const data = await response.json();
//                 setForecast(data.list);
//             } catch (error) {
//                 console.error('데이터 에러', error);
//             }
//         };

//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition(position => {
//                 fetchWeatherData(position.coords.latitude, position.coords.longitude);
//             }, () => {
//                 fetchWeatherByCity('Seoul');
//             });
//         } else {
//             fetchWeatherByCity('Seoul');
//         }
//     }, []);

//     const fetchWeatherByCity = (selectedCity) => {
//         setCity(selectedCity);
//         fetch(`https://api.openweathermap.org/data/2.5/forecast?q=${selectedCity}&appid=${Weather_API_KEY}&units=metric&lang=kr`)
//             .then(response => response.json())
//             .then(data => {
//                 setForecast(data.list);
//             })
//             .catch(error => console.error('데이터 에러', error));
//     }

//     const handleCityChange = (event) => {
//         fetchWeatherByCity(event.target.value);
//     }

//     const startOfDay = new Date();
//     startOfDay.setHours(0, 0, 0, 0);
//     const endOfDay = new Date(startOfDay);
//     endOfDay.setDate(startOfDay.getDate() + 1);

//     const todayForecast = forecast.filter(entry => {
//         const entryDate = new Date(entry.dt_txt.replace(" ", "T"));
//         return entryDate >= startOfDay && entryDate < endOfDay;
//     });

//     const subsequentForecast = forecast.filter(entry => {
//         const entryDate = new Date(entry.dt_txt.replace(" ", "T"));
//         return entryDate >= endOfDay;
//     });

//     //요일
//     const getKoreanDayOfWeek = (dateStr) => {
//         const date = new Date(dateStr);
//         date.setUTCHours(date.getUTCHours() + 9);
//         const daysInKorean = ["일", "월", "화", "수", "목", "금", "토"];
//         return daysInKorean[date.getUTCDay()];
//     }




//     ///추가

//     const handleEntryClick = (day) => {
//         setSelectedWeather(groupedForecast[day]);
//         setShowModal(true);
//     };

//     const handleCloseModal = () => {
//         setShowModal(false);
//     };

//     const groupedForecast = subsequentForecast.reduce((acc, entry) => {
//         const dayOfWeek = getKoreanDayOfWeek(entry.dt_txt);
//         if (!acc[dayOfWeek]) acc[dayOfWeek] = [];
//         acc[dayOfWeek].push(entry);
//         return acc;
//     }, {});

//     const dailyForecasts = Object.keys(groupedForecast).map(day => {
//         const temps = groupedForecast[day].map(entry => entry.main.temp);
//         const uniqueWeatherDescriptions = [...new Set(groupedForecast[day].map(entry => entry.weather[0].description))];
//         const weatherDescription = uniqueWeatherDescriptions.length === 1 ? uniqueWeatherDescriptions[0] : groupedForecast[day][0].weather[0].description;
//         const weatherIcon = groupedForecast[day][0].weather[0].icon;

//         return {
//             ...groupedForecast[day][0],
//             minTemp: Math.min(...temps),
//             maxTemp: Math.max(...temps),
//             dayWeatherDescription: weatherDescription,
//             dayWeatherIcon: weatherIcon
//         };
//     });



//     return (
//         <div className='noScroll'>
//             <div className="Weather-Wrap">
//             <h1 className="Title">날씨정보페이지</h1>
//             <select value={city} onChange={handleCityChange}>
//                     <option value="Seoul">서울</option>
//                     <option value="Busan">부산</option>
//                     <option value="Incheon">인천</option>
//                     <option value="Daegu">대구</option>
//                     <option value="Daejeon">대전</option>
//                     <option value="Gwangju">광주</option>
//                     <option value="Suwon">수원</option>
//                     <option value="Ulsan">울산</option>
//                     <option value="Seongnam">성남</option>
//                     <option value="Goyang">고양</option>
//                     <option value="Yongin">용인</option>
//                     <option value="Bucheon">부천</option>
//                     <option value="Ansan">안산</option>
//                     <option value="Jeju">제주</option>
//                     <option value="Anyang">안양</option>
//                     <option value="Gimhae">김해</option>
//                     <option value="Pohang">포항</option>
//                     <option value="Cheongju">청주</option>
//                 </select>
//             <div className='forecast-row'>
//                 {todayForecast.map((entry, index) => {
//                     const { icon, className } = mapWeatherToIconAndClass(entry.weather[0].icon);
//                     return (
//                         <div key={index} className="forecast-entry">
//                             <div className='forecast_text'>시간: {entry.dt_txt.split(" ")[1].slice(0, 5)}</div>
//                             <div className='forecast_text'>온도: {entry.main.temp}°</div>
//                             <div className='forecast_text'>날씨: {entry.weather[0].description}</div>
//                             <FontAwesomeIcon icon={icon} className={className} />
//                         </div>
//                     );
//                 })}
//             </div>
//             <div className='forecast-col'>
//                 {dailyForecasts.map((entry, index) => {
//                     const { icon, className } = mapWeatherToIconAndClass(entry.dayWeatherIcon);
//                     return (
//                         <div key={index} className="forecast-entry" onClick={() => handleEntryClick(getKoreanDayOfWeek(entry.dt_txt))}>
//                             <div className='forecast_text'> {getKoreanDayOfWeek(entry.dt_txt)}</div>
//                             <FontAwesomeIcon icon={icon} className={className} />
//                             <div className='forecast_text'> {entry.minTemp}°</div>
//                             <div className='forecast_text'> {entry.maxTemp}°</div>
//                         </div>
//                     );
//                 })}
//             </div>
//             {showModal && selectedWeather && (
//                 <div id="modal-background" className="modal" onClick={handleCloseModal}>
//                     <div className="modal-content">
//                         <h2>{getKoreanDayOfWeek(selectedWeather[0].dt_txt)}요일 날씨</h2>
//                         {selectedWeather.map((entry, idx) => {
//                             const { icon, className } = mapWeatherToIconAndClass(entry.weather[0].icon);
//                             return (
//                                 <div key={idx} className='modal-item'>
//                                     <div>시간: {entry.dt_txt.split(" ")[1].slice(0, 5)}</div>
//                                     <div>온도: {entry.main.temp}°C</div>
//                                     <div>날씨: {entry.weather[0].description}</div>
//                                     <FontAwesomeIcon icon={icon} className={className} />
//                                 </div>
//                             );
//                         })}
//                     </div>
//                 </div>
//             )}
//                 </div>
//         </div>
// );
//                 }



import { useState, useEffect } from 'react';
import '../css/Weather.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSun,
    faCloudSun,
    faCloud,
    faCloudShowersHeavy,
    faBolt,
    faSnowflake,
    faSmog,
    faMoon,
    faCloudMoon,
    faCloudMeatball,
    faCloudSunRain,
    faCloudMoonRain
} from '@fortawesome/free-solid-svg-icons';
import { MapContainer, TileLayer, useMapEvents, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Weather_API_KEY } from '../../firebase';
import L from 'leaflet';

// 요일 변환 함수
const getKoreanDayOfWeek = (dateStr) => {
    const daysInKorean = ['일', '월', '화', '수', '목', '금', '토'];
    const date = new Date(dateStr);
    return daysInKorean[date.getDay()];
};

const simpleMarkerIcon = new L.DivIcon({
    className: 'custom-div-icon',
    html: `<div style="
        width: 15px;
        height: 15px;
        background-color: #0078ff;
        border-radius: 50%;
        border: 2px solid white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    "></div>`,
    iconSize: [15, 15],
    iconAnchor: [7.5, 7.5],
    popupAnchor: [0, -10],
});

const southKoreaBounds = [
    [33.0, 124.0], // 남서쪽 경계
    [39.5, 131.0], // 북동쪽 경계
];
const mapWeatherToIconAndClass = (iconCode) => {
    switch (iconCode) {
        case '01d': return { icon: faSun, className: 'icon-clear-sky-day' };
        case '01n': return { icon: faMoon, className: 'icon-clear-sky-night' };
        case '02d': return { icon: faCloudSun, className: 'icon-few-clouds-day' };
        case '02n': return { icon: faCloudMoon, className: 'icon-few-clouds-night' };
        case '03d':
        case '03n': return { icon: faCloud, className: 'icon-scattered-clouds' };
        case '04d':
        case '04n': return { icon: faCloudMeatball, className: 'icon-broken-clouds' };
        case '09d':
        case '09n': return { icon: faCloudShowersHeavy, className: 'icon-shower-rain' };
        case '10d': return { icon: faCloudSunRain, className: 'icon-rain-day' };
        case '10n': return { icon: faCloudMoonRain, className: 'icon-rain-night' };
        case '11d':
        case '11n': return { icon: faBolt, className: 'icon-thunderstorm' };
        case '13d':
        case '13n': return { icon: faSnowflake, className: 'icon-snow' };
        case '50d':
        case '50n': return { icon: faSmog, className: 'icon-mist' };
        default: return { icon: faSun, className: 'icon-clear-sky-day' };
    }
};

export const Weather = () => {
    const [forecast, setForecast] = useState([]);
    const [location, setLocation] = useState({ lat: 37.5665, lng: 126.9780 });
    const [cityName, setCityName] = useState('서울특별시');
    const [showModal, setShowModal] = useState(false);
    const [selectedWeather, setSelectedWeather] = useState(null);

    // 지도 클릭 핸들러
    const MapClickHandler = () => {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng;
                setLocation({ lat, lng });
            },
        });
        return null;
    };

    useEffect(() => {
        const fetchWeatherData = async (latitude, longitude) => {
            try {
                const response = await fetch(
                    `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${Weather_API_KEY}&units=metric&lang=kr`
                );
                const data = await response.json();
                setForecast(data.list || []);
                fetchCityName(latitude, longitude);
            } catch (error) {
                console.error('데이터 에러', error);
            }
        };

        const fetchCityName = async (latitude, longitude) => {
            try {
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=ko`
                );
                const data = await response.json();
                setCityName(
                    data.address.city ||
                    data.address.town ||
                    data.address.village ||
                    data.address.state ||
                    '알 수 없음'
                );
            } catch (error) {
                console.error('지명 가져오기 에러', error);
                setCityName('알 수 없음');
            }
        };

        fetchWeatherData(location.lat, location.lng);
    }, [location]);

    const handleEntryClick = (dayWeather) => {
        setSelectedWeather(dayWeather);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const groupedForecast = forecast.reduce((acc, entry) => {
        const date = entry.dt_txt.split(' ')[0];
        if (!acc[date]) acc[date] = [];
        acc[date].push(entry);
        return acc;
    }, {});

    const dailyForecasts = Object.keys(groupedForecast).map((date) => {
        const dayEntries = groupedForecast[date];
        const temps = dayEntries.map((entry) => entry.main.temp);
        const uniqueDescriptions = [...new Set(dayEntries.map((entry) => entry.weather[0].description))];
        const weatherIcon = dayEntries[0].weather[0].icon;

        return {
            date,
            minTemp: Math.min(...temps),
            maxTemp: Math.max(...temps),
            description: uniqueDescriptions.join(', '),
            icon: weatherIcon,
            entries: dayEntries,
        };
    });

    return (
        <div className='noScroll'>
            <div className="Weather-Wrap">
                <h1 className="Title">날씨정보페이지</h1>
                <MapContainer
                    center={[37.5665, 126.9780]} // 서울 중심
                    zoom={7}
                    style={{ height: '400px', width: '80%' }}
                    maxBounds={southKoreaBounds} // 대한민국 경계 설정
                    bounds={southKoreaBounds} // 초기 경계
                    scrollWheelZoom={false} // 스크롤 줌 비활성화
                    attributionControl={false} // 우측 하단 문구 제거
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MapClickHandler />
                    <Marker
                        position={[location.lat, location.lng]}
                        icon={simpleMarkerIcon}
                    />
                </MapContainer>
                <div className="location-info">선택된 위치 : {cityName}</div>
                <div className='forecast-row'>
                    {forecast.slice(0, 8).map((entry, index) => {
                        const { icon, className } = mapWeatherToIconAndClass(entry.weather[0].icon);
                        const time = entry.dt_txt.split(" ")[1].slice(0, 5); 
                        return (
                            <div key={index} className="forecast-entry">
                                <div className='forecast_text'>시간 : {time}</div>
                                <div className='forecast_text'>온도 : {entry.main.temp}°C</div>
                                <div className='forecast_text'>{entry.weather[0].description}</div>
                                <FontAwesomeIcon icon={icon} className={className} />
                            </div>
                        );
                    })}
                </div>
                <div className='forecast-col'>
                    {dailyForecasts.map((day, index) => {
                        const { icon, className } = mapWeatherToIconAndClass(day.icon);
                        return (
                            <div key={index} className="forecast-entry" onClick={() => handleEntryClick(day.entries)}>
                                <div className='forecast_text'>{getKoreanDayOfWeek(day.date)}</div>
                                <FontAwesomeIcon icon={icon} className={className} />
                                <div className='forecast_text'>최저 : {day.minTemp.toFixed(1)}°C</div>
                                <div className='forecast_text'>최고 : {day.maxTemp.toFixed(1)}°C</div>
                            </div>
                        );
                    })}
                </div>
                {showModal && selectedWeather && (
                    <div id="modal-background" className="modal" onClick={handleCloseModal}>
                        <div className="modal-content">
                            <h2>날씨 상세 정보</h2>
                            {selectedWeather.map((entry, idx) => {
                                const { icon, className } = mapWeatherToIconAndClass(entry.weather[0].icon);
                                const time = entry.dt_txt.split(" ")[1].slice(0, 5); // 시간 HH :mm 포맷
                                return (
                                    <div key={idx} className='modal-item'>
                                        <div>시간 : {time}</div>
                                        <div>온도 : {entry.main.temp}°C</div>
                                        <div>날씨 : {entry.weather[0].description}</div>
                                        <FontAwesomeIcon icon={icon} className={className} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
